import { useEffect, useState } from 'react'
import { useStoreExchange } from '../store/useStoreExchange'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import {
  selectCryptoAmount,
  selectCryptoCurrency,
  selectFetchPrices,
  selectFiatAmount,
  selectFiatCurrency
} from '../store/selectors/ExchangeSelectors'
import { selectFetchExchangeAmountLimit, selectFetchPaymentTypes } from '../store/selectors/ExchangePageSelectors'
import { cfgExchange } from '../DefaultValues'

type ExchangePageProps = {
  pathname: string
}

type ExchangePageReturn = {
  isInitialized: boolean
  isStep5: boolean
  fiatAmount: string
  fiatCurrency: string
  cryptoAmount: string
  cryptoCurrency: string
}

export const useExchangePage = ({ pathname }: ExchangePageProps): ExchangePageReturn => {
  const [isInitialized, setIsInitialized] = useState(false)
  const fiatAmount = useStoreExchange(selectFiatAmount)
  const fiatCurrency = useStoreExchange(selectFiatCurrency)
  const cryptoAmount = useStoreExchange(selectCryptoAmount)
  const cryptoCurrency = useStoreExchange(selectCryptoCurrency)
  const fetchPrices = useStoreExchange(selectFetchPrices)
  const fetchPaymentTypes = useStoreExchangePage(selectFetchPaymentTypes)
  const fetchExchangeAmountLimit = useStoreExchangePage(selectFetchExchangeAmountLimit)
  const isStep5 = (pathname === `/exchange/${cfgExchange.STEPS.STEP5.route}`)

  useEffect(() => {
    const load = async () => {
      await fetchPrices()
      await fetchPaymentTypes()
      await fetchExchangeAmountLimit()
    }
    load().then(() => setIsInitialized(true))
  }, [fetchPrices, fetchPaymentTypes, fetchExchangeAmountLimit])

  return {
    isInitialized,
    isStep5,
    fiatAmount,
    fiatCurrency,
    cryptoAmount,
    cryptoCurrency,
  }
}
