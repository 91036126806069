import { useLocation, useRouteMatch } from "react-router-dom";
import { useHeaderAuth } from "../useHeaderAuth";
import { useMemo } from "react";

interface IStep {
  path: string;
  name: string;
}
export const useTopUpCard = (steps: IStep[]) => {
  const { isAuthorized, isInitialized } = useHeaderAuth();
  const { pathname } = useLocation();
  const { path } = useRouteMatch();
  const currentStep = useMemo(() => {
    return (
      steps.findIndex((step) => pathname.includes(`${path}/${step.path}`)) + 1
    );
  }, [path, pathname]);
  const isInstructionPage = pathname.toLowerCase().endsWith("/instruction");
  const isFirstPage = currentStep === 1;
  const isLastPage = currentStep === steps.length;
  const isHashPage = pathname.includes("/instruction/hash");
  return {
    isAuthorized,
    isInitialized,
    path,
    currentStep,
    isInstructionPage,
    isFirstPage,
    isLastPage,
    isHashPage,
  };
};
