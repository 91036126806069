import { useEffect, useState } from 'react'
import { ExClient } from '../ExClient'

type AvailableCountryReturn = {
  code: string
  name: string
  isEnabled: boolean
}

export const useAvailableCountry = (): AvailableCountryReturn => {
  const [country, setCountry] = useState({
    code: '',
    name: '',
    isEnabled: false
  })

  useEffect(() => {
    const fetchCountry = async () => {
      const { code, name, is_enabled } = await ExClient.getCountryByIp()
      setCountry({ code, name, isEnabled: is_enabled === '1' })
    }
    fetchCountry().then()
  }, [])

  return country
}
