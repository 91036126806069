import React, { Component } from "react";
import { ApiData } from "../ApiData";
import Select from "react-select";

export interface IDefaultSelectNativeProps {
  selected?: any;
  type: string;
  onChange?: (event: any) => void;
  key_equals_value?: boolean;
  with_default?: boolean;
  name?: string;
  id?: string;
  className?: string;
  classNamePrefix?: string;
  styles?: any;
  isSearchable?: boolean;
}

export interface Opt {
  label: string;
  value: string;
}

export class DefaultSelectNative extends Component<IDefaultSelectNativeProps> {
  state = {
    data_list: new Array<Opt>(),
    selected: this.props.selected,
  };

  public async componentDidMount(): Promise<void> {
    return ApiData.getSelectorList(this.props.type).then((data: any) => {
      //console.log("DSN", this.props.type, data)
      let list = data.map((opt: any) => ({
        label: opt.key,
        value: this.props.key_equals_value ? opt.key : opt.value,
      }));

      if (this.props.with_default)
        list.unshift({
          label: "All",
          value: 0,
        });

      let newState = {
        data_list: list,
      };

      if (this.props.selected == null) {
        if (list.length > 0)
          //@ts-ignore
          newState["selected"] = list[0];
      } else if (
        typeof this.props.selected === "string" ||
        typeof this.props.selected === "number"
      ) {
        // selected prop is not option, just some value
        //@ts-ignore
        newState["selected"] = list.find(
          (e: any) => e.value === this.props.selected
        );
      }

      this.setState(newState);
    });
  }

  getSelected = () => {
    return this.state.selected;
  };

  handleChange = (selectedOption: any) => {
    this.setState({ selected: selectedOption });
    if (this.props.onChange) this.props.onChange(selectedOption);
  };

  render = () => (
    <Select
      options={this.state.data_list}
      components={{ DropdownIndicator: () => null }}
      value={this.state.selected}
      className={this.props.className}
      classNamePrefix={this.props.classNamePrefix}
      styles={this.props.styles}
      onChange={this.handleChange}
      name={this.props.name}
      id={this.props.id}
      isSearchable={this.props.isSearchable}
    />
  );
}
