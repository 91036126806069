export type Modify<T, R> = Omit<T, keyof R> & R;

export type Country = {
  code: string;
  name: string;
  is_enabled: boolean;
  region_id: number;
  region_name: string;
};

export type CountryRegion = {
  id: number;
  name: string;
};

export enum ClientType {
  OnRamp,
  Default,
}
