import {UrlUtils} from "./utils/UrlUtils";

export enum ACTIONS {
    Exchange = "exchange",
    EmailVerifyExchange = "email_verify_exchange"
}

export abstract class ExchangeSession
{
    static get email_temp(): string | null {
        return localStorage.getItem("email_temp");
    }

    static set email_temp(value: string | null) {
        if (value === undefined)
            throw new Error('Please enter valid value');
        if (value == null)
            localStorage.removeItem("email_temp");
        else
            localStorage.setItem("email_temp", value);
    }

    static get action(): string | null {
        return localStorage.getItem("action");
    }

    static set action(value: string | null) {
        if (value === undefined)
            throw new Error('Please enter valid value');

        if(value == null)
            localStorage.removeItem("action");
        else
            localStorage.setItem("action", value);
    }


    static get exchangeDataJson(): string | null {
        return localStorage.getItem("exchange.data");
    }

    static set exchangeDataJson(value: string | null) {
        if (value === undefined)
            throw new Error('Please enter valid value');
        if(value === null)
            localStorage.removeItem("exchange.data");
        else
            localStorage.setItem("exchange.data", value);
    }

    static get data(): any {
        let d = this.exchangeDataJson;

        if(d == null)
            return {}

        return JSON.parse(d);
    }

    static set data(value: any) {
        if (value === undefined)
            throw new Error('Please enter valid value');

        this.exchangeDataJson = JSON.stringify(value);
    }

    static saveDataParam(param_name: string, value: string)
    {
        let data = this.data;
        if(!data)
            data = {};
        data[param_name] = value;
        this.data = data;
    }

    static getDataParam(param_name: string)
    {
        let data = this.data;
        if(!data)
            return null;
        return data[param_name];
    }

    static cancel() {
        this.data = null;
    }

    static redirectIfWrongStep(current_step: string)
    {
        let data = ExchangeSession.data;

        if(!data) {
            if (current_step != "1")
                UrlUtils.redirectTo("exchange?step=1");
        }
        else if(data["step"] != current_step)
        {
            UrlUtils.redirectTo("exchange?step=" + (data['step'] ? data['step'] : "1"));
        }
    }

    static getExchangeState()
    {
        let data = ExchangeSession.data;

        if(data && data["fiat_input"] && data["fiat_select"] && data["crypto_select"])
        {
            const fiat_amount = parseFloat(data["fiat_input"]);
            const crypto_amount = parseFloat(data["crypto_input"]);
            return{
                    fiat_amount: fiat_amount,
                    crypto_amount: crypto_amount,
                    fiatCurrency: data["fiat_select"],
                    cryptoCurrency: data["crypto_select"],
                    wallet: data["wallet"]
                };
        }
        return false;
    }
}