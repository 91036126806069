import React, { Component, ReactNode } from "react";

export interface ITimerProps {
  count_down?: number;
  text?: string;
  onUpdate: () => void;
  enabled?: boolean;
  className?: string;
  wrapWithTag?: string;
  secText?: string;
  format?: string;
  render?: (text: string | number) => ReactNode;
}
const formatNum = (num: number) => (num < 10 ? `0${num}` : num);
export class Timer extends Component<ITimerProps, {}> {
  public static defaultProps = {
    count_down: 60,
    text: "Recalculated in",
    enabled: true,
    wrapWithTag: "p",
    secText: "sec",
  };

  state = {
    timer_countdown: this.props.count_down,
  };
  private timer: any;

  public componentDidMount(): void {
    if (this.props.enabled === false) return;

    this.timer = setInterval(() => {
      if (this.state.timer_countdown > 0) {
        this.setState({
          timer_countdown: this.state.timer_countdown - 1,
        });
      } else {
        this.setState({ timer_countdown: this.props.count_down });
        this.props.onUpdate();
      }
    }, 1000);
    //this.props.onUpdate();
  }

  public componentWillUnmount(): void {
    clearInterval(this.timer);
  }

  render = () => {
    const { timer_countdown } = this.state;
    const {
      format,
      text,
      className,
      wrapWithTag,
      secText,
      render,
    } = this.props;
    let countDown: string | number = timer_countdown;
    if (format != null) {
      let seconds = countDown;
      const hour = formatNum(Math.floor(seconds / 3600));
      const minute = formatNum(Math.floor(seconds / 60));
      const second = formatNum(seconds % 60);
      countDown = format
        .replace(/hh/i, String(hour))
        .replace(/mm/i, String(minute))
        .replace(/ss/i, String(second));
    }
    if (render != null) return render(countDown);
    if (!wrapWithTag)
      return (
        <div className={className}>
          {`${text} `}
          <span>
            {countDown}
            {secText}
          </span>
        </div>
      );
    if (wrapWithTag !== "")
      return React.createElement(
        wrapWithTag,
        { className },
        `${text} ${countDown} ${secText}`
      );
  };
}
