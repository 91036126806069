import { ExchangeStore } from '../useStoreExchange'
import { ExchangeSelector } from '../../types/ExchangeTypes'

export const selectFiatAmount = (state: ExchangeStore) => state.fiatAmount
export const selectFiatCurrency = (state: ExchangeStore) => state.fiatCurrency
export const selectFiatCurrencies = (state: ExchangeStore) => state.currencies.fiat

export const selectCryptoAmount = (state: ExchangeStore) => state.cryptoAmount
export const selectCryptoCurrency = (state: ExchangeStore) => state.cryptoCurrency
export const selectCryptoCurrencies = (state: ExchangeStore) => state.currencies.crypto

export const selectFetchPrices = (state: ExchangeStore) => state.fetchPrices
export const selectFetchCurrencies = (state: ExchangeStore) => state.fetchCurrencies

export const selectFiat: ExchangeSelector = (state: ExchangeStore) => ({
  amount: selectFiatAmount(state),
  currency: selectFiatCurrency(state),
  currencies: selectFiatCurrencies(state),
  setAmount: state.setFiatAmount,
  setCurrency: state.setFiatCurrency,
})

export const selectCrypto: ExchangeSelector = (state: ExchangeStore) => ({
  amount: selectCryptoAmount(state),
  currency: selectCryptoCurrency(state),
  currencies: selectCryptoCurrencies(state),
  setAmount: state.setCryptoAmount,
  setCurrency: state.setCryptoCurrency,
})
