import { useCallback, useEffect, useState } from "react";
import { ShortTransaction } from "../../Wallet";
import { ApiData } from "../../ApiData";

type TransactionsProps = {
  type: string;
  pageSize: number;
};

type TransactionsReturn = {
  data: ShortTransaction[];
  dataTotal: number;
  isLoading: boolean;
  isInitialized: boolean;
  pageCount: number;
  pageIndex: number;
  setPageIndex: (val: number) => void;
  onFilter: (filter: any) => void;
};

export const useTransactions = ({
  type = "Buy",
  pageSize,
}: TransactionsProps): TransactionsReturn => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [filter, setFilter] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [dataTotal, setDataTotal] = useState(0);

  useEffect(() => {
    const load = async () => await loadData();
    load().then(() => setIsInitialized(true));
  }, [filter, pageIndex]);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    const {
      success,
      data: { records, recordsTotal },
    } = await ApiData.clientApiRequest(
      {
        filter,
        paged: true,
        count: pageSize,
        offset: pageSize * pageIndex,
      },
      `get_last_${type.toLowerCase()}`,
      "wallet"
    );

    setIsLoading(false);
    if (!success) return;

    setData(records);
    setDataTotal(recordsTotal);
    setPageCount(Math.ceil(recordsTotal / pageSize));
  }, [filter, pageIndex]);

  const onFilter = (filter: any) => {
    setPageIndex(0);
    setFilter(filter);
  };

  return {
    data,
    dataTotal,
    isLoading,
    isInitialized,
    pageCount,
    pageIndex,
    setPageIndex,
    onFilter,
  };
};
