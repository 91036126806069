import { useEffect, useMemo } from "react";
import shallow from "zustand/shallow";
import { useTUCardStore } from "../../store/useTopUpCard";

export const useCurrencyTable = (isCreateCard: boolean = false) => {
  const { prices, deposit, fiat, amounts } = useTUCardStore(
    (state) => ({
      prices: state.prices,
      deposit: state.deposit,
      fiat: state.fiat,
      amounts: state.amounts,
    }),
    shallow
  );
  const fetchPrices = useTUCardStore((state) => state.fetchPrices);
  const fetchAmounts = useTUCardStore((state) => state.fetchOrderCardAmounts);
  useEffect(() => {
    fetchPrices();
    if (isCreateCard) fetchAmounts();
  }, [fetchPrices, fetchAmounts, isCreateCard]);

  const E_Currencies = useMemo(() => {
    const payment =
      amounts != null ? amounts.monthly_fee + amounts.order_fee : 0;
    const depositBuffer = !isCreateCard ? deposit : String(payment);
    return Object.keys(prices.crypto).map((symbol) => {
      const fee = prices?.fees?.find((fee) => fee.symbol === symbol);
      const spend = (
        parseFloat(depositBuffer || "0") *
        (prices.fiat[fiat] ?? 1) *
        (prices.crypto[symbol] ?? 1)
      ).toFixed(5);

      return {
        amount: depositBuffer,
        completion: 0,
        fee: fee?.commission ?? 0,
        spend,
        name: fee?.currency ?? "",
        symbol,
      };
    });
  }, [isCreateCard, prices, deposit, fiat, deposit, amounts]);

  return { tableData: E_Currencies, amounts };
};
