import { ExClient, ICard, IVerifiedSections } from "../ExClient";
import create, { State } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { ClientType } from "../types/CommonTypes";

export type User = {
  id?: number;
  email?: string;
  first_name?: string;
  last_name?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  country_code?: string;
  country_name?: string;
  phone?: string;
  sex?: number;
  is_verified?: boolean;
  verify_flags?: any;
  date_created?: string;
  date_last_login?: string;
  avatar?: string;
  card_active?: boolean;
  client_type?: ClientType;
  app?: string;
};

export interface ClientStore extends State {
  clientCardsCache?: Record<string, ICard[]>;
  clientCards: ICard[];
  clientCardsFilter: { count: number; offset: number };
  clientCardsTotal: number;
  clientCardsLoading: boolean;

  activeCards: ICard[];
  activeCardsLoading: boolean;

  cardTypes: string[];
  isAllExist: boolean;
  user: User;
  uploaded_types?: Record<string, string>;

  setUser: (values: User) => void;
  setUploadedTypes: (types: IVerifiedSections["data"]) => void;
  fetchActiveCards: (refetch?: boolean) => void;
  fetchClientCards: (refetch?: boolean, pageSize?: number) => void;
  setFilter: (count: number, offset: number) => void;
  clearCache: () => void;
}

export const useStoreClient = create<ClientStore>(
  devtools(
    persist(
      (set, get) => ({
        clientCards: [],

        clientCardsFilter: { count: 6, offset: 0 },
        clientCardsTotal: 0,
        activeCards: [],
        cardTypes: [],
        activeCardsLoading: true,

        clientCardsLoading: false,
        isAllExist: true,
        user: {
          id: 0,
          is_verified: false,
          first_name: "",
          last_name: "",
        },
        clearCache: () => {
          set((state) => ({
            clientCardsCache: {},
            activeCards: [],
          }));
        },
        setUploadedTypes: (types: IVerifiedSections["data"]) => {
          return set((state) => ({
            ...state,
            uploaded_types: types.reduce((acc, item) => {
              Object.assign(acc, { [item.type_id]: item.exist });
              return acc;
            }, {}),
          }));
        },
        fetchClientCards: async (
          refetch: boolean = false,
          pageSize: number = 6
        ) => {
          const { count, offset } = get().clientCardsFilter;
          const cache = get().clientCardsCache;
          const key = `count:${count}|offset:${offset}`;
          if (key in (cache ?? {}) && !refetch)
            set((state) => ({ ...state, clientCards: cache[key] }));
          else {
            set((state) => ({ ...state, clientCardsLoading: true }));
            const { data, recordsTotal } = await ExClient.getCards(
              false,
              offset * pageSize,
              count
            );
            set((state) => ({
              clientCardsTotal: recordsTotal,
              clientCardsCache: {
                ...state.clientCardsCache,
                [key]: data,
              },
              clientCards: data,
              clientCardsLoading: false,
            }));
          }
        },
        setFilter: (count, offset) => {
          set((state) => ({
            ...state,
            clientCardsFilter: { count, offset },
          }));
          get().fetchClientCards();
        },
        fetchActiveCards: async (refetch: boolean = false) => {
          if (get().activeCards.length > 0 && !refetch) return;
          set((state) => ({ ...state, activeCardsLoading: true }));
          const { data } = await ExClient.getCards(true);
          if (data != null) {
            set((state) => ({
              ...state,
              activeCards: data,
              activeCardsLoading: false,
              isAllExist:
                data.length < 2
                  ? false
                  : (data ?? []).some((card) =>
                      ["1", "2"].includes(card.card_type)
                    ),
            }));
          }
        },
        setUser: (values) =>
          set((state) => ({ user: { ...state.user, ...values } })),
      }),
      {
        name: "__client_data__",
        getStorage: () => localStorage,
        partialize: (state: ClientStore) => ({
          user: { client_type: (state.user as User).client_type },
        }),
      }
    ),
    { name: "useStoreClient" }
  )
);
