import { ExchangePageStore } from '../useStoreExchangePage'

export const selectStep1 = (state: ExchangePageStore) => state.step1
export const selectSetStep1 = (state: ExchangePageStore) => state.setStep1
export const selectPaymentTypes = (state: ExchangePageStore) => state.paymentTypes
export const selectFetchPaymentTypes = (state: ExchangePageStore) => state.fetchPaymentTypes
export const selectExchangeAmountLimit = (state: ExchangePageStore) => state.exchangeAmountLimit
export const selectFetchExchangeAmountLimit = (state: ExchangePageStore) => state.fetchExchangeAmountLimit

export const selectStep2 = (state: ExchangePageStore) => state.step2
export const selectSetStep2 = (state: ExchangePageStore) => state.setStep2

export const selectStep3 = (state: ExchangePageStore) => state.step3
export const selectSetStep3 = (state: ExchangePageStore) => state.setStep3

export const selectStep4 = (state: ExchangePageStore) => state.step4
export const selectSetStep4 = (state: ExchangePageStore) => state.setStep4
