import { useCallback, useEffect, useState } from "react";
import { ApiData, EDepositStatus } from "../../ApiData";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";
import { useTUCardStore } from "../../store/useTopUpCard";

export const useStatus = () => {
  const history = useHistory();
  const setDepositData = useTUCardStore(state => state.setDepositStatus);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<EDepositStatus | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState(0);
  const { token } = useParams<{ token: string }>();

  const fetchStatus = useCallback(async () => {
    setLoading(true);
    const result = await ApiData.getStatusDeposit(token);
    if (result?.success === false) {
      toast.error(result?.message ?? "Error!");
      setError(result.message);
    } else {
      setStatus(result.data.status);
      setDepositData(result.data);
      setConfirmation(result.data.confirmation);
    }
    setLoading(false);
  }, [token, history]);

  useEffect(() => {
    if (token != null) fetchStatus();
    else history.push("by-crypto");
  }, [fetchStatus, token]);

  return { fetchStatus, error, status, loading, confirmation };
};
