//@ts-nocheck
import $ from 'jquery'

export abstract class DynamicUiUtils {
  static Break = {
    mb: 420,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1540,
    any: 1 / 0,
  }

  static Win = {
    height: $(window).height(),
    width: $(window).outerWidth(),
  }

  public static setFormDataArrayById(selector: string, data: any) {
    $.each(data, function (key: string, value: string) {
      $('[name=' + key + ']', selector).val(value)
    })
  }

  static merge(e: any, t: any) {
    return (
      Object.keys(t).forEach(function (n) {
        e[n] = t[n]
      }),
      e
    )
  }

  static overlay(n: any, i: any, panel: any) {
    var i
    if (panel.break === true) {
      i = $(n).data('toggle-screen')
      panel.break = this.Break[i]
    }
    if ($(n).hasClass(panel.content) && this.Win.width < panel.break) {
      $(n).after('<div class="' + i + '" data-target="' + panel.self + '"></div>')
    } else {
      this.overlayRemove(i)
    }
  }

  static overlayRemove(name: string) {
    $('.' + name)
      .fadeOut(300)
      .remove()
  }

  static toggle(Constructor: any, attr: any) {
    let i = $('body')
    let defaults = {
      self: Constructor,
      active: 'active',
      content: 'expanded',
      data: 'content',
      olay: 'toggle-overlay',
      speed: 400,
    }
    let options = attr ? this.merge(defaults, attr) : defaults
    let $parent = $('[data-target=' + Constructor + ']')
    let $container = $('[data-' + options.data + '=' + Constructor + ']')
    let c = $container.data('toggle-body')
    if ($container.data('toggle-overlay')) {
      options.overlay = options.olay
    }
    if (c) {
      /** @type {string} */
      options.body = 'toggle-shown'
    }
    if ($container.hasClass(options.content)) {
      $parent.removeClass(options.active)
      if (options.toggle == 1) {
        $container.slideUp(options.speed).removeClass(options.content)
      } else {
        $container.removeClass(options.content)
      }
    } else {
      $parent.addClass(options.active)
      if (options.toggle == 1) {
        $container.slideDown(options.speed).addClass(options.content)
      } else {
        $container.addClass(options.content)
      }
    }
    if (options.body) {
      i.toggleClass(options.body)
    }
    if (options.overlay) {
      this.overlay($container, options.overlay, options)
    }
  }
}
