import { useCallback, useEffect, useState } from "react";
import { useLocation, useParams, useRouteMatch } from "react-router-dom";
import { ExClient } from "../../ExClient";
import { toast } from "react-toastify";
import { useDownloadStatement } from "./useDownloadStatement";

export interface ICardTransactionItem {
  id: string;
  transaction: any;
  date_created: string;
  amount: string;
  currency: string;
  month_year: string;
  card_pan: string;
  fee: string;
  status: string;
  error_message: string;
}
export const useCardTransactions = ({ page_size }: { page_size: number }) => {
  const { id } = useParams<{ id?: string }>();
  const { downloadStatement, loading } = useDownloadStatement();
  const [page, setPage] = useState(0);
  const [dataTotal, setDataTotal] = useState(0);
  const [data, setData] = useState<ICardTransactionItem[]>([]);
  const [filter, setFilter] = useState({});
  const path = useRouteMatch().path;
  const { pathname } = useLocation();
  const isCardPage = /\/card\/?$/.test(pathname);

  const fetchCards = useCallback(async () => {
    const card_id = id;
    const result = await ExClient.getCardLast(card_id, {
      filter,
      paged: true,
      count: page_size,
      offset: page_size * page,
    });
    if (result != null) {
      setData(result.data.records);
      setDataTotal(result.data.recordsTotal);
    } else toast.error("Error!");
  }, [id, filter, page]);

  useEffect(() => {
    fetchCards();
  }, [fetchCards]);

  const fetchStatement = useCallback(async () => {
    const cardId = id != null ? Number(id) : undefined;
    downloadStatement(cardId, filter);
  }, [id, filter]);

  const pageCount = Math.ceil(dataTotal / page_size);
  const pageOptions = Array.from({ length: pageCount }).map((_, i) => i);
  return {
    data,
    setFilter,
    fetchCards,
    fetchStatement,
    dataTotal,
    isCardPage,
    path,
    setPage,
    page,
    pageCount,
    pageOptions,
    loading,
  };
};
