import create, { State } from 'zustand'
import { devtools } from 'zustand/middleware'
import { getIsLoggedIn } from './apiRequests/exchangeRequests'

export interface MainStore extends State {
  init: () => Promise<boolean>
  isAuthorized: boolean
  isAuthInitialized: boolean
  setIsAuthorized: (isAuthorized: boolean) => void
}

export const useStoreMain = create<MainStore>(devtools((set) => ({
  isAuthorized: false,//ExClient.isAuthorized,
  isAuthInitialized: false,
  init: async () => {
    const isAuthorized = await getIsLoggedIn()

    set({ isAuthorized, isAuthInitialized: true })
    return isAuthorized
  },
  setIsAuthorized: isAuthorized => set({ isAuthorized })
}), { name: 'useStoreMain' }))
