import React, {Component} from "react";
import { ExClient } from "./ExClient";
import {UrlUtils} from "./utils/UrlUtils";

export interface ILoginState {
    showEmailVerifyInfo: boolean;
    showIPVerifyInfo: boolean;
}

interface ILoginProps {
    onInfo: (msg: string) => void
}

export class LoginModel extends Component<ILoginProps, ILoginState> {
    constructor(props: ILoginProps) {
        super(props);
        this.state = {
            showEmailVerifyInfo: false,
            showIPVerifyInfo: false,
        }
    }
    public componentDidMount(): void {
        this.checkLogin();
        this.checkUrl();
    }

    private async checkLogin()
    {
        const result = await ExClient.checkLogin();
        console.log("CHECK LOGIN", result);
        if(result) {
            ExClient.redirectLogin();
        }
    }


    private checkUrl()
    {
        console.log("checkUrl");
        if(window.location.hash == "#success_confirm_email") {
            this.setState({
                showEmailVerifyInfo: true
            });
        }
        else if(window.location.hash == "#success_confirm_ip")
        {
            this.setState({
                showIPVerifyInfo: true
            });
        }
        else if(window.location.hash == "#registered")
        {
            // @ts-ignore
            $("#email_sent").modal('toggle');
        }
        if(window.location.hash == "#exists")
            this.props.onInfo("This email already registered");


        let email = UrlUtils.getUrlParam('email');
        if(email)
            $("#email").val(email);
    }
}
