import { CardViewStore } from "../useStoreCardView";
import { ClientStore } from "../useStoreClient";

export const selectUser = (state: ClientStore) => state.user;
export const selectSetUser = (state: ClientStore) => state.setUser;
export const selectCardView = (state: CardViewStore) => ({
  fetchCardDetails: state.fetchCardDetails,
  loading: state.cardLoading,
  card: state.card,
});
export const selectClientCards = (state: ClientStore) => ({
  cards: state.clientCards,
  cardTypes: state.cardTypes,
  fetchClientCards: state.fetchClientCards,
  loading: state.clientCardsLoading,
  setFilter: state.setFilter,
  totalRecords: state.clientCardsTotal,
  filter: state.clientCardsFilter,
  isAllExist: state.isAllExist,
});
