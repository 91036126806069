import QRCode from "qrcode";
import { useState } from "react";

export const useQRCode = () => {
  const [result,setResult]=useState<string|null>(null)
  const [loading,setLoading]=useState(false)
  const generate = (
    data: string,
  ) => {
      setLoading(true)
      QRCode.toDataURL(data, (err, url) => {
        if (!err) setResult(url);
        else setResult(null);
        setLoading(false)
    });
  };
  
  return {generate ,result, loading };
}
