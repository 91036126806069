import React, {Component} from "react";
import {DefaultSelectNative, Opt} from "./DefaultSelectNative";


export interface ICountrySelectNativeProps {
    selected?: any;
    onChangeCountry?:  (event: Opt) => void,
    with_default?:boolean,

    className?: string
    classNamePrefix?: string
    styles?:any
    isSearchable?:boolean
}

export class CountrySelectNative extends Component<ICountrySelectNativeProps> {
    selector: React.RefObject<DefaultSelectNative > = React.createRef();

    getSelector = () => {return this.selector; }
    getSelected = () => {return this.selector.current.getSelected() }

    render = () => (
        <DefaultSelectNative
            className={this.props.className} classNamePrefix={this.props.classNamePrefix} styles={this.props.styles}
            selected={this.props.selected} type={"country"} onChange={this.props.onChangeCountry} key_equals_value={false} with_default={this.props.with_default}
            isSearchable={this.props.isSearchable == null ? true : this.props.isSearchable} ref={this.selector}
        />
    );
}
