import { ExClient } from '../ExClient'
import { useStoreMain } from '../store/useStoreMain'
import { getIsAuthInitialized, getIsAuthorized } from '../store/selectors/MainSelectors'

type HeaderAuthReturn = {
  isAuthorized: boolean
  isInitialized: boolean
  onLogout: () => void
}

export const useHeaderAuth = (): HeaderAuthReturn => {
  const isAuthorized = useStoreMain(getIsAuthorized)
  const isInitialized = useStoreMain(getIsAuthInitialized)

  return {
    isAuthorized,
    isInitialized,
    onLogout: async () => await ExClient.logout()
  }
}
