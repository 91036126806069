import { useEffect, useState } from 'react'
import { useStoreExchange } from '../store/useStoreExchange'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import { selectStep1, selectStep4 } from '../store/selectors/ExchangePageSelectors'
import { fetchRateAndCommission } from '../store/apiRequests/exchangeRequests'
import { selectCryptoCurrency, selectFiatCurrency } from '../store/selectors/ExchangeSelectors'

type HookReturn = {
  wallet: string
  ex_transaction_id: number
  fee: number
  rateAmount: number
}

export const useExchangeCartTotals = (): HookReturn => {
  const fiatCurrency = useStoreExchange(selectFiatCurrency)
  const cryptoCurrency = useStoreExchange(selectCryptoCurrency)
  const { wallet } = useStoreExchangePage(selectStep1)
  const { ex_transaction_id } = useStoreExchangePage(selectStep4)

  const [fee, setFee] = useState(0)
  const [rateAmount, setRateAmount] = useState(0)

  useEffect(() => {
    const getRateAndFee = async () => {
      const { rate, transaction_fee_pct } = await fetchRateAndCommission(fiatCurrency, cryptoCurrency)
      setRateAmount(rate)
      setFee(transaction_fee_pct)
    }
    getRateAndFee().then()
  }, [fiatCurrency, cryptoCurrency])

  return {
    wallet,
    ex_transaction_id,
    fee,
    rateAmount,
  }
}
