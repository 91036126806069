import { useCallback, useEffect, useState } from "react";
import { CardStatus, Exact, QueryType } from "../../types/CardTypes";
import { useStoreCardView } from "../../store/useStoreCardView";
import shallow from "zustand/shallow";
import { ExClient } from "../../ExClient";

export const useCardNumberAndCvv = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    cardCvv,
    cardNumber,
    toggleCvv,
    togglePan,
    cvvToggled,
    panToggled,
    status,
  } = useStoreCardView(
    (state) => ({
      cardCvv: state.cardCvv,
      cardNumber: state.cardNumber,
      panToggled: state.panToggled,
      cvvToggled: state.cvvToggled,
      toggleCvv: state.toggleCvv,
      togglePan: state.togglePan,
      status: state.card.status,
    }),
    shallow
  );
  const toggleAll = useCallback(() => {
    const open = !isOpen;
    setIsOpen(open);
    togglePan(open);
    toggleCvv(open);
  }, [toggleCvv, togglePan, isOpen]);
  return {
    cardCvv,
    cardNumber,
    cardClosed: status === CardStatus.Closed,
    toggleCvv,
    togglePan,
    toggleAll,
    panToggled,
    cvvToggled,
  };
};
