import * as React from 'react';
import {ApiData} from "../ApiData";


export interface ISelectorApiDataState {
    data_list: any[];
}

export interface SelectorApiDataProps {
    selected?: string;
    key_equals_value:boolean
    type: string,
    with_default?: boolean
}

export default class OptionsApiData extends React.Component<SelectorApiDataProps, ISelectorApiDataState>  {
    state = {
        data_list: [{}]
    }

    public componentDidMount(): void {
        ApiData.getSelectorList(this.props.type).then((data: any) => {
            if(this.props.with_default)
                data.unshift({
                    value: 0,
                    key: "All"
                });
            this.setState({
                data_list: data,
            });

            console.log("SSSE", this.props.selected, data)
        });
    };

    public render = () => {
        return <>
            {this.state.data_list.map((k: any, index: number) => <option key={index} value={this.props.key_equals_value ? k.key : k.value} selected={this.props.selected && this.props.selected == k.value}>{k.key}</option>)}
        </>
    }
}