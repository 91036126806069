import { Component } from "react";
import { CLIENT_EVENTS } from "./utils/EventUtils";
import { ExClientInfo } from "./ExClientInfo";

export class ExClientInfoComponent extends Component<{}, ExClientInfo> {
    state = new ExClientInfo()

    unsubscribeClientInfoEvent: () => void;

    componentDidMount(): void {
        this.unsubscribeClientInfoEvent = CLIENT_EVENTS.onClientInfoUpdate((payload) => {
            console.log("Update event:", payload)
            this.setState(payload)
        });
    }

    public componentWillUnmount(): void {
        this.unsubscribeClientInfoEvent();
    }
}