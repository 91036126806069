import { useEffect, useState } from "react";
import { ApiData } from "../ApiData";
import { Country, CountryRegion, Modify } from "../types/CommonTypes";

type AvailableCountriesReturn = {
  countries: Array<Country>;
  regions: Array<CountryRegion>;
  regionId: number;
  isShowMore: boolean;
  onShowMore: () => void;
  onRegionChange: (val: number) => void;
  isSupport: Country | false;
};

let regions: Array<CountryRegion> = [];
let allCountries: Array<Country> = [];

export const useAvailableCountries = ({
  limit = 5,
}: { limit?: number } = {}): AvailableCountriesReturn => {
  // @ts-ignore
  const lang = navigator.language || navigator.userLanguage;
  const regionCode = lang.split("-")[1] as string;
  const [isSupport, setIsSupport] = useState<Country | null | false>(null);
  const [regionId, setRegionId] = useState(0);
  const [countries, setCountries] = useState([]);
  const [isShowMore, setIsShowMore] = useState(false);

  useEffect(() => {
    const fetchCountries = async () => {
      const { data } = await ApiData.publicApiRequest({}, "get_country_list");

      allCountries = data.reduce(
        (
          acc: Country[],
          cur: Modify<Country, { is_enabled: string; region_id: string }>
        ) => {
          const region_id = parseInt(cur.region_id);
          if (!regions.some((e) => e.name === cur.region_name))
            regions.push({ id: region_id, name: cur.region_name });

          return [
            ...acc,
            { ...cur, region_id, is_enabled: cur.is_enabled === "1" },
          ];
        },
        []
      );
      setIsSupport(
        allCountries.find((country) => country.code === regionCode) ?? false
      );
      setCountries(allCountries.slice(0, limit));
    };
    fetchCountries().then();
  }, []);

  useEffect(() => {
    if (!allCountries.length) return;

    let res =
      regionId > 0
        ? allCountries.filter((c) => c.region_id === regionId)
        : allCountries;
    if (!isShowMore) res = res.slice(0, limit);

    setCountries(res);
  }, [regionId, isShowMore]);

  const onRegionChange = (val: number) => {
    setRegionId(val);
    setIsShowMore(false);
  };

  return {
    countries,
    regions,
    regionId,
    isShowMore,
    onShowMore: () => setIsShowMore(true),
    onRegionChange,
    isSupport,
  };
};
