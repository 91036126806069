import parser from "ua-parser-js";

export const maskCardNumber = (card_pan?: string) => {
  return card_pan
    ?.split("")
    .map((char, index) => {
      if (index > 3 && index < card_pan.length - 4) return "*";
      return char;
    })
    .join("");
};

export const clearStorage = () => {
  sessionStorage.clear();
};

export function collectDeviceData() {
  let deviceType = "Desktop";
  if (/Android/i.test(navigator.userAgent)) {
    deviceType = "Android";
  } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
    deviceType = "iOS";
  } else if (
    /Mobile|Tablet|iPad|iPod|IEMobile|Opera Mini/i.test(navigator.userAgent)
  ) {
    deviceType = "Other Mobile";
  }
  const browserData = {
    userAgent: navigator.userAgent,
    javaEnabled: navigator.javaEnabled(),
    cookiesEnabled: navigator.cookieEnabled,
    browserLanguage: navigator.language,
  };

  const screenData = {
    screenWidth: window.screen.width,
    screenHeight: window.screen.height,
    colorDepth: window.screen.colorDepth,
  };
  const parsedData = parser(browserData.userAgent);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const deviceData = {
    os: { name: parsedData.os?.name, version: parsedData.os?.version },
    browser: parsedData.browser,
    deviceType,
    browserData,
    screenData,
    timeZone,
  };

  return deviceData;
}
