import { useCallback, useEffect, useMemo, useState } from "react";
import shallow from "zustand/shallow";
import { ExClient } from "../ExClient";
import { PaymentType } from "../types/ExchangeTypes";
import { useStoreMain } from "../store/useStoreMain";
import { useStoreExchange } from "../store/useStoreExchange";
import { useStoreExchangePage } from "../store/useStoreExchangePage";
import {
  getIsAuthorized,
  selectSetIsAuthorized,
} from "../store/selectors/MainSelectors";
import { selectSetStep1 } from "../store/selectors/ExchangePageSelectors";
import {
  fetchExchangeCurrencies,
  getIsEmailVerified,
  getIsLoggedIn,
} from "../store/apiRequests/exchangeRequests";
import { validateWallet } from "../helpers/ExchangeHelpers";
import { cfgExchange } from "../DefaultValues";
import { History } from "history";

type ExchangeStep0Props = {
  search: string;
  pathname: string;
  msgInvalidWallet?: string;
  msgEnterValidWallet?: string;
};

type ExchangeStep0Return = {
  search: string;
  pathname: string;
};

export const useExchangeStep0 = ({
  search,
  pathname,
  msgInvalidWallet = "Invalid wallet!",
  msgEnterValidWallet = "Please enter a valid wallet.",
}: ExchangeStep0Props): ExchangeStep0Return => {
  const [route, setRoute] = useState("");
  const [withSearch, setWithSearch] = useState(true);
  const isAuthorized = useStoreMain(getIsAuthorized);
  const setIsAuthorized = useStoreMain(selectSetIsAuthorized);
  const setStep1 = useStoreExchangePage(selectSetStep1);
  const [setFiatAmount, setFiatCurrency, setCryptoCurrency] = useStoreExchange(
    (state) => [
      state.setFiatAmount,
      state.setFiatCurrency,
      state.setCryptoCurrency,
    ],
    shallow
  );

  const query = useMemo(() => new URLSearchParams(search), [search]);
  if (!query.has("step")) {
    useStoreExchangePage((state) => state.reset)();
  }

  const checkVerified = useCallback(
    async (query: URLSearchParams) => {
      const email = query.get("email");
      let paymentType: PaymentType = {
        id: ExClient.payment_id,
        name: ExClient.payment_name,
        title: "",
      };
      const wallet = query.get("wallet");
      const fiatAmount = query.get("fiat_input");
      const fiatCurrency = query.get("fiat_select");
      const cryptoCurrency = query.get("crypto_select");

      setStep1({ email, paymentType });
      if (!(await getIsEmailVerified(email))) {
        setWithSearch(false);
        return setRoute(cfgExchange.STEPS.STEP1.route);
      }

      if (wallet) {
        const walletErr = await validateWallet(
          isAuthorized,
          wallet,
          cryptoCurrency,
          fiatAmount,
          fiatCurrency,
          msgInvalidWallet,
          msgEnterValidWallet
        );
        if (walletErr === "") setStep1({ wallet });
      }

      const { fiat, crypto } = await fetchExchangeCurrencies(paymentType.id);

      setFiatAmount(fiatAmount);
      if (fiat.includes(fiatCurrency)) setFiatCurrency(fiatCurrency);
      if (crypto.includes(cryptoCurrency)) setCryptoCurrency(cryptoCurrency);

      setWithSearch(false);
      setRoute(cfgExchange.STEPS.STEP2.route);
    },
    [isAuthorized, setStep1, setFiatAmount, setFiatCurrency, setCryptoCurrency]
  );

  useEffect(() => {
    const check = async () => await getIsLoggedIn();
    check().then((res) => setIsAuthorized(res));
  }, []);

  useEffect(() => {
    if (query.has("step") && query.get("step") === "5")
      return setRoute(cfgExchange.STEPS.STEP5.route);

    if (
      query.has("fiat_input") &&
      query.has("fiat_select") &&
      query.has("crypto_select")
    ) {
      setFiatAmount(query.get("fiat_input"));
      setFiatCurrency(query.get("fiat_select"));

      setCryptoCurrency(query.get("crypto_select"));
    } else if (query.has("to")) {
      setCryptoCurrency(query.get("to"));
    }

    if (!query.has("email") /* || !query.has('p_id')*/)
      return setRoute(cfgExchange.STEPS.STEP1.route);

    checkVerified(query).then();
  }, [query, checkVerified, setCryptoCurrency, setFiatAmount, setFiatCurrency]);

  return {
    search: withSearch ? search : "",
    pathname: `${pathname}/${route}`.replace("//", "/"),
  };
};
