import { useState } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import { ApiData } from "../ApiData";

type FormProps = {
  email: string;
};

type RecoveryFormReturn = {
  formMethods: UseFormReturn<FormProps>;
  onSubmit: (data: FormProps) => void;
  email: string;
  setEmail: (value: string) => void;
  loading: boolean;
};

export const useRecoveryForm = <
  TFormValues extends Record<string, any> = Record<string, any>
>(): RecoveryFormReturn => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(null);

  const formMethods = useForm<FormProps>();
  const onSubmit = async (data: FormProps) => {
    setLoading(true);
    const { email } = data;
    const { success, message } = await ApiData.clientApiRequest(
      { email },
      "reset_password_email",
      null
    );
    if (!success) {
      setLoading(false);
      return toast.error(message);
    }

    setEmail(email);
    formMethods.reset();
    setLoading(false);
  };

  return {
    formMethods,
    onSubmit,
    email,
    setEmail,
    loading,
  };
};
