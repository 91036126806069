import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ExClient, ITransactionInfo } from "../../ExClient";

export const useTransactionInfo = (transactionId: string) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITransactionInfo | null>(null);
  const getTransactionInfo = useCallback(
    async (id: string) => {
      try {
        setLoading(true);
        const result = await ExClient.getTransactionInfo(id);
        if (result?.error_message != null) toast.error(result.error_message);
        else setData(result);
        setLoading(false);
      } catch {
        console.error("Failed get Transaction error");
        setLoading(false);
      }
    },
    [setLoading, setData]
  );
  return { loading, data, fetch: getTransactionInfo, show, setShow };
};
