import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { ExClient } from "../../ExClient";
import { toast } from "react-toastify";

interface ITransactionHashForm {
  hash: string;
}
export const useTransactionHash = () => {
  const navigate = useHistory();
  const { token } = useParams<{ token: string }>();
  const methods = useForm<ITransactionHashForm>();
  useEffect(() => {
    if (token == null) navigate.replace("/top-up-card/by-crypto");
  }, [token]);
  const sendHash = useCallback(
    async (data: ITransactionHashForm) => {
      const result = await ExClient.sendHash(data.hash, token);
      if (result.success) {
        navigate.replace(`/top-up-card/status/${token}`);
      } else toast.error(result.message ?? "Something went wrong");
    },
    [token]
  );
  return { methods, sendHash };
};
