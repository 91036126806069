import React, { useState } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Modal } from 'react-bootstrap'
import { ExClient } from '../ExClient'
import { Timer } from './Timer'
import { getIsLoggedIn } from '../store/apiRequests/exchangeRequests'

let defaultTimeout = 10
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  defaultTimeout = 1
}

type AutoLogoutProps = {
  timeout?: number
  textSec?: string
  textYes?: string
  textTimer?: string
  textWantToStay?: string
  textWillLogout?: string
}

async function checkLoggedIn() {
  if (!await getIsLoggedIn())
    await ExClient.logout()
}

export const AutoLogout = ({
  timeout = defaultTimeout,
  textSec =  'sec',
  textYes =  'Yes',
  textTimer = 'Otherwise you will be logged out in',
  textWantToStay = 'Do you want to stay logged in?',
  textWillLogout = 'You will be automatically logged out due inactivity for security reasons.'
}: AutoLogoutProps): JSX.Element => {
  const [showModal, setShowModal] = useState(false)

  const onYes = async () => {
    setShowModal(false)

    await ExClient.extendSession()
    start()
  }

  const onIdle = async () => {
    // console.log('handleOnIdle')
    await checkLoggedIn()
    if (document.hidden) return

    pause()
    setShowModal(true)
  }

  const onTimerUpdate = async () => {
    setShowModal(false)
    // console.log('await ExClient.logout()')
    await ExClient.logout()
  }

  const onActive = async () => {
    // console.log('onActive')
    await checkLoggedIn()
  }

  const onAction = () => {
    // console.log('onAction')
  }

  const { pause, start } = useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1000 * 60 * timeout,
    debounce: 300,
    crossTab: {
      emitOnAllTabs: true
    }
  })

  return <Modal show={showModal} className="auto-logout" onHide={() => {}}>
    <Modal.Body>
      <p>{textWillLogout}</p>
      <p><strong>{textWantToStay}</strong></p>
      <div className="row">
        <div className="col-sm-3">
          <button className="button button-sm button-green" onClick={onYes}>{textYes}</button>
        </div>
        <div className="col-sm-9">
          <Timer
            enabled={showModal}
            count_down={10}
            text={textTimer}
            onUpdate={onTimerUpdate}
            className="timer"
            secText={textSec}
          />
        </div>
      </div>
    </Modal.Body>
  </Modal>
}
