import { useEffect } from 'react'
import { useStoreExchange } from '../store/useStoreExchange'
import { ExchangeSelector } from '../types/ExchangeTypes'
import {
  selectFetchCurrencies,
  selectFetchPrices,
  selectFiat,
  selectCrypto
} from '../store/selectors/ExchangeSelectors'

export const useExchangeFormMini = (): [ExchangeSelector, ExchangeSelector] => {
  const fetchPrices = useStoreExchange(selectFetchPrices)
  const fetchCurrencies = useStoreExchange(selectFetchCurrencies)

  useEffect(() => {
    fetchPrices()
    fetchCurrencies()
  }, [fetchPrices, fetchCurrencies])

  return [selectFiat, selectCrypto]
}
