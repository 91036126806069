import { useEffect, useState } from 'react'
import { LOCALE_KEY_NAME } from '../constants/CommonConstants'

type langProps = {
  keyName?: string
  languages: Array<{
    code: string
    label: string
    messages: any
  }>
  folder?: string
}

type langReturn = {
  locale: string
  basename: string
  messages: any
}

export const useLang = ({ languages, keyName = LOCALE_KEY_NAME, folder = '' }: langProps): langReturn => {
  const defaultLang = languages[0]
  const [language, setLanguage] = useState(null)

  useEffect(() => {
    if (language !== null) return

    const langUrl = window.location.pathname.split('/')[1]
    const langUrlFound = languages.find(lang => lang.code.toLowerCase().substring(0, 2) === langUrl)

    const langStorage = localStorage.getItem(keyName)
    const langStorageFound = languages.find(lang => lang.code === langStorage)

    let langNeeded = defaultLang
    if (langUrlFound) {
      langNeeded = langUrlFound
    } else if (langStorageFound) {
      langNeeded = langStorageFound
    }

    const langCode = langNeeded.code.toLowerCase().substring(0, 2)
    if (langUrl !== langCode) {
      const { pathname, search } = window.location
      window.location.href = `/${langCode}${pathname}${search}`
      return
    }
    setLanguage(langNeeded)
  }, [keyName, languages, language, defaultLang])

  useEffect(() => {
    if (language === null) return
    localStorage.setItem(keyName, language.code)
    document.documentElement.lang = language.code.substring(0, 2)
  }, [keyName, language])

  const { code, messages } = language || { code: '' }

  return {
    locale: code,
    basename: '/' + code.toLowerCase().substring(0, 2) + folder,
    messages
  }
}
