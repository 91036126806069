import { History } from "history";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import { ApiData } from "../ApiData";
import { ExClient } from "../ExClient";
import { useStoreMain } from "../store/useStoreMain";
import { useStoreExchangePage } from "../store/useStoreExchangePage";
import { selectStep1 } from "../store/selectors/ExchangePageSelectors";
import { selectSetIsAuthorized } from "../store/selectors/MainSelectors";
import {
  ExchangeStep1Values,
  ExchangeStep2Values,
} from "../types/ExchangeTypes";
import { cfgExchange } from "../DefaultValues";
import { useState } from "react";

type ExchangeStep2Props = {
  history: History;
  msgRegistered?: string;
};

type ExchangeStep2Return = {
  formMethods: UseFormReturn<ExchangeStep1Values>;
  onSubmit: (data: ExchangeStep1Values) => void;
  loading: boolean;
};

export const useExchangeStep2 = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  history,
  msgRegistered = "Registered!",
}: ExchangeStep2Props): ExchangeStep2Return => {
  const [loading, setLoading] = useState(false);
  const { email } = useStoreExchangePage(selectStep1);
  const setIsAuthorized = useStoreMain(selectSetIsAuthorized);

  const formMethods = useForm<ExchangeStep2Values>({
    defaultValues: {
      sex: "1",
      country_code: ExClient.ip_country_code,
    },
  });

  const onSubmit = async (data: ExchangeStep2Values) => {
    setLoading(true);
    const body = { ...data, email, shop_id: ExClient.shop_id };
    const { success, message } = await ApiData.clientApiRequest(
      body,
      "register_form",
      null
    );
    if (!success) {
      setLoading(false);
      return toast.error(message);
    }

    toast.success(msgRegistered);
    await ExClient.sendRegEvent("exchange");
    setIsAuthorized(true);
    setLoading(false);
    history.push(cfgExchange.STEPS.STEP3.route);
  };

  return {
    formMethods,
    onSubmit,
    loading,
  };
};
