import { useEffect } from 'react'
import { useStoreExchangePage } from '../store/useStoreExchangePage'
import { selectStep1 } from '../store/selectors/ExchangePageSelectors'
import { getIsEmailVerified } from '../store/apiRequests/exchangeRequests'

export const useExchangeStep1Verify = (onVerified: () => void): string => {
  const { email } = useStoreExchangePage(selectStep1)

  useEffect(() => {
    const checkVerified = async () => {
      if (await getIsEmailVerified(email))
        onVerified()
    }

    const interval = setInterval(() => {
      checkVerified().then()
    }, 3000)

    return () => clearInterval(interval)
  }, [email, onVerified])

  return email
}
