import create, { GetState, SetState, State } from 'zustand'
import { devtools, persist, StoreApiWithPersist } from 'zustand/middleware'
import { ExClient } from '../ExClient'
import { fetchExchangeAmountLimit, fetchPaymentTypes } from '../store/apiRequests/exchangeRequests'
import {
  PaymentType,
  ExchangeStep1Values,
  ExchangeStep2Values,
  ExchangeStep3Values,
  ExchangeStep4Values,
  ExchangeStep5Values, ExchangeAmountLimit,
} from '../types/ExchangeTypes'
import { EXCHANGE_PAGE_STORAGE_KEY } from '../constants/ExchangeConstants'

export interface ExchangePageStore extends State {
  step1: ExchangeStep1Values
  step2: ExchangeStep2Values
  step3: ExchangeStep3Values
  step4: ExchangeStep4Values
  step5: ExchangeStep5Values
  paymentTypes: PaymentType[]
  fetchPaymentTypes: () => void
  exchangeAmountLimit: ExchangeAmountLimit
  fetchExchangeAmountLimit: () => void
  setStep1: (values: ExchangeStep1Values) => void
  setStep2: (values: ExchangeStep2Values) => void
  setStep3: (values: ExchangeStep3Values) => void
  setStep4: (values: ExchangeStep4Values) => void
  setStep5: (values: ExchangeStep5Values) => void
  reset: () => void
}

export const useStoreExchangePage = create<ExchangePageStore, SetState<ExchangePageStore>, GetState<ExchangePageStore>, StoreApiWithPersist<ExchangePageStore>>(persist(devtools((set, get) => ({
  step1: {
    tryAgain: false
  },
  step2: {},
  step3: {},
  step4: {},
  step5: {},
  paymentTypes: [],
  exchangeAmountLimit: {},
  fetchPaymentTypes: async () => {
    const paymentTypes = await fetchPaymentTypes()
    if (paymentTypes.length === 0) return

    paymentTypes.forEach(({ id, name, title }, index) => {
      if (name === 'card') {
        paymentTypes.splice(index, 1)
        paymentTypes.unshift({
          id: id,
          name: 'visa',
          title: title,
        }, {
          id: id,
          name: 'mastercard',
          title: title,
        }, {
          id: id,
          name: 'maestro',
          title: title,
        })
      }
    })

    set({ paymentTypes: paymentTypes })

    let paymentType: PaymentType = get().step1.paymentType || {
      id: ExClient.payment_id,
      name: ExClient.payment_name,
      title: '',
    }

    let selectedIndex = paymentTypes.findIndex(({ id }: PaymentType) => id === paymentType.id)
    paymentType = selectedIndex === -1 ? paymentTypes[0] : paymentTypes[selectedIndex]

    get().setStep1({ paymentType })
  },
  fetchExchangeAmountLimit: async () => {
    const exchangeAmountLimit = await fetchExchangeAmountLimit()
    set({ exchangeAmountLimit })
  },
  setStep1: (values) => set(state => ({ step1: { ...state.step1, ...values } })),
  setStep2: (values) => set(state => ({ step2: { ...state.step2, ...values } })),
  setStep3: (values) => set(state => ({ step3: { ...state.step3, ...values } })),
  setStep4: (values) => set(state => ({ step4: { ...state.step4, ...values } })),
  setStep5: (values) => set(state => ({ step5: { ...state.step5, ...values } })),
  reset: () => set(state => ({
    step1: {
      ...state.step1,
      email: '',
      wallet: '',
      agree_terms: false,
      agree_refund: false,
      agree_age: false,
      captcha: '',
      tryAgain: false
    },
    step2: {},
    step3: {},
    step4: {},
    step5: {},
  }))
}), { name: 'useStoreExchangePage' }), {
  name: EXCHANGE_PAGE_STORAGE_KEY,
  getStorage: () => sessionStorage,
  blacklist: ['step3']
}))

useStoreExchangePage.subscribe((step1: ExchangeStep1Values, prevStep1: ExchangeStep1Values) => {
  if (!prevStep1.paymentType)
    return

  if (step1.paymentType.id !== prevStep1.paymentType.id || !ExClient.payment_id)
    ExClient.payment_id = step1.paymentType.id

  if (step1.paymentType.name !== prevStep1.paymentType.name || !ExClient.payment_name)
    ExClient.payment_name = step1.paymentType.name

}, state => state.step1)
