
export const uploadModalInfo = {
    "1":{
        title: "Upload and verify ID",
        info: "Get instant verification of your ID/driver’s license/passport. To make sure your application is accepted, please read the requirements first.",
        level: 2,
        type: "PASSPORT"
    },
    "2": {
        title: "Proof of address",
        info: "Please upload your proof of residency document. It could be a utility bill, electricity bill or bank statement. To make sure your application is accepted, please read the requirements first. Verification could take up to 2 business days. We will notify you when completed.",
        level: 3,
        type: "UTILITY_BILL"
    },
    "3": {
        title: "Photo of payment card",
        info: " Please upload a copy of the payment card used revealing the first six and the last four digits.",
        level: 2,
        type: "CARD"
    },
    "4": {
        title: "Photo of a selfie with a card in hand",
        info: "Get instant verification a selfie with a passport in hand. To make sure your application is accepted, please read the requirements first.",
        level: 2,
        type: "SELFIE_PASSPORT"
    },
    "5":{
        title: "Photo of a selfie with a card in hand",
        info: "Get instant verification a selfie with a card in hand. To make sure your application is accepted, please read the requirements first.",
        level: 2,
        type: "SELFIE_CARD"
    }
};