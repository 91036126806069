import {ExClientInfo} from "../ExClientInfo";
import { WalletState } from "../Wallet";
import EventEmitter from "eventemitter3";

export enum EmitterEvents {
    ClientLogged = "CLIENT_LOGGED",
    ClientLoggedOut = "CLIENT_LOGGED_OUT",
    ClientInfoUpdate = "CLIENT_INFO_UPD",
    ClientBalance = "CLIENT_BALANCE"
}

export const CLIENT_EVENTS = {
    onClientLogged: (callback: (payload: {client_id: number}) => void) => {
        Emitter.on(EmitterEvents.ClientLogged, callback)
        return () => Emitter.off(EmitterEvents.ClientLogged, callback)
    },
    onClientLoggedOut: (callback: () => void) => {
        Emitter.on(EmitterEvents.ClientLoggedOut, callback)
        return () => Emitter.off(EmitterEvents.ClientLoggedOut, callback)
    },
    onClientInfoUpdate: (callback: (payload: ExClientInfo) => void) => {
        Emitter.on(EmitterEvents.ClientInfoUpdate, callback)
        return () => Emitter.off(EmitterEvents.ClientInfoUpdate, callback)
    },
    onClientBalance: (callback: (payload: WalletState) => void) => {
        Emitter.on(EmitterEvents.ClientBalance, callback)
        return () => Emitter.off(EmitterEvents.ClientBalance, callback)
    }
}

export const Emitter = new EventEmitter()