import shallow from "zustand/shallow";
import { useTUCardStore } from "../../store/useTopUpCard";

export const useInformation = () => {
  const values = useTUCardStore(
    state => ({
      cryptoAmount: state.depositData?.crypto_amount ?? (0).toFixed(2),
      crypto: state.crypto,
      deposit: state.deposit,
      fee: state.depositData?.fee ?? (0).toFixed(5),
      cryptoPrice: state.depositData?.amount_withdrawal ?? (0).toFixed(5),
      card: state.card,
      fiat: state.fiat
    }),
    shallow
  );
  return {
    ...values,
    deposit: parseFloat(values.deposit).toFixed(2)
  };
};
