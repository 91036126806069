import React, {Component} from "react";
import {ApiData} from "./ApiData";


export class ExchangeStartModel extends Component {
    state = {
        fiatCurrency: "EUR",
        cryptoCurrency: "BTC",

        fiat_amount: 0,
        crypto_amount: 0,

        fiat_prices: {"EUR": 1},
        crypto_prices: {},
        is_authorized: false,
        temp_email: "",
        is_loading: true,
        is_checking: true
    }

    onChangeFiat = (selected: any) => {
        if(selected) {
            this.changeFiat(selected.label);
        }
    }

    changeFiat = (fiatCurrency: string) => {
        this.setState(
            {
                fiatCurrency: fiatCurrency,
                crypto_amount: this._getCryptoInput(this.state.fiat_amount, fiatCurrency, this.state.cryptoCurrency)
            });
    }

    onChangeCrypto = (selected: any) => {
        if(selected) {
            this.changeCrypto(selected.label);
        }
    }

    changeCrypto = (cryptoCurrency: string) => {
        console.log("changeCrypto", cryptoCurrency);
        this.setState(
            {
                cryptoCurrency: cryptoCurrency,
                crypto_amount: this._getCryptoInput(this.state.fiat_amount, this.state.fiatCurrency, cryptoCurrency)
            });
    }

    onChangeCryptoReact = (event: React.ChangeEvent<HTMLSelectElement>) => {

        console.log("change", event)
        this.changeCrypto(event.target.selectedOptions[0].text);
    }

    onChangeFiatReact = (event: React.ChangeEvent<HTMLSelectElement>) => {
        this.changeFiat(event.target.selectedOptions[0].text);
    }

    onInputFiat = (event: React.ChangeEvent<HTMLInputElement>) => {
        let amount = Number(event.target.value);
        if(isNaN(amount) || amount > 1000000)
            return;

        this.setState(
            {
                fiat_amount: event.target.value,
                crypto_amount: this._getCryptoInput(amount, this.state.fiatCurrency, this.state.cryptoCurrency)
            });
    }

    onInputCrypto = (event: React.ChangeEvent<HTMLInputElement>) => {
        let amount = Number(event.target.value);
        if(isNaN(amount) || amount > 1000000)
            return;

        this.setState(
            {
                crypto_amount: event.target.value,
                fiat_amount: this._getFiatInput(amount, this.state.cryptoCurrency)
            });
    }


    _getCryptoInput(fiat_amount: number, cur_fiat: string, cur_crypto: string)
    {
        if(!this.state.fiat_prices)
            return 0;
        //@ts-ignore
        var result = fiat_amount / this.state.fiat_prices[cur_fiat] / this.state.crypto_prices[cur_crypto];
        return result.toFixed(6);
    }

    _getFiatInput(crypto_amount: number, cur: string)
    {
        //@ts-ignore
        var result = crypto_amount * this.state.fiat_prices[this.state.fiatCurrency] * this.state.crypto_prices[cur];
        return result.toFixed(2);
    }



    public loadData(amount:number = 200): void {
        ApiData.getCurrenciesExchange().then((data) => {
            this.setState({
                fiat_amount: amount,
                fiat_prices: data.fiat_prices,
                crypto_prices: data.crypto_prices,
            });

            this.setState(
                {
                    fiatCurrency: this.state.fiatCurrency,
                    crypto_amount: this._getCryptoInput(this.state.fiat_amount, this.state.fiatCurrency, this.state.cryptoCurrency)
                },
                () => this.setState({is_loading: false})
            )
        });
    };



}
