import { ExDocumentType } from "./types/ExDocumentType";
import { ArrayUtils } from "./utils/ArrayUtils";
import { DynamicUiUtils } from "./utils/DynamicUiUtils";
import { CLIENT_EVENTS, Emitter } from "./utils/EventUtils";
import { ViewUtils } from "./utils/ViewUtils";
import { NetUtils } from "./utils/NetUtils";
import { StringUtils } from "./utils/StringUtils";
import { UrlUtils } from "./utils/UrlUtils";
import { ExTransactionStatus } from "./types/ExTransactionStatus";
import { Timer } from "./components/Timer";
import CurrencyInput from "./components/CurrencyInput";
import { CurrencySelect } from "./components/CurrencySelect";
import OptionsApiData from "./components/OptionsApiData";
import { CurrencySelectNative } from "./components/CurrencySelectNative";
import { CountrySelectNative } from "./components/CountrySelectNative";
import { DefaultSelectNative } from "./components/DefaultSelectNative";
import { uploadModalInfo } from "./types/uploadModalInfo";
import { ExceptionCodes } from "./ApiData";
export { ExchangeSession } from "./ExchangeSession";
export { ExchangeStartModel } from "./ExchangeStartModel";
export { ExClient, Verification } from "./ExClient";

export { ExClientInfo } from "./ExClientInfo";
export { ExClientInfoComponent } from "./ExClientInfoComponent";
export { ExMarketInfo } from "./ExMarketInfo";
export { Wallet, ShortTransaction, WalletState } from "./Wallet";
export { WalletCommonTransaction } from "./WalletCommonTransaction";
export { WalletTotalStatistic } from "./WalletTotalStatistic";
export { CommonMenuModel } from "./CommonMenuModel";
export {
  DefaultValues,
  setConfigUrls,
  cfgExchange,
  setConfigExchange,
} from "./DefaultValues";
export { ApiData, ApiError, getApiDataPromise } from "./ApiData";
export { LoginModel } from "./LoginModel";

export { useDropUpload } from "./hooks/useDropUpload";

export { useStoreClient } from "./store/useStoreClient";
export { selectUser } from "./store/selectors/ClientSelectors";
export { selectClientCards } from "./store/selectors/ClientSelectors";
export { useClientApp } from "./hooks/client/useClientApp";
export { useCardLimit } from "./hooks/client/useCardLimit";
export { useCardTransactions } from "./hooks/client/useCardTransactions";
export type { ICardTransactionItem } from "./hooks/client/useCardTransactions";
export type { ICard } from "./ExClient";
export { useTransactionInfo } from "./hooks/client/useTransactionInfo";
export { useVerifyPersonalForm } from "./hooks/client/useVerifyPersonalForm";
export { useVerifyWalletForm } from "./hooks/client/useVerifyWalletForm";
export { useExchangeBox } from "./hooks/client/useExchangeBox";
export { useCryptoStocks } from "./hooks/client/useCryptoStocks";
export { useRecentActivities } from "./hooks/client/useRecentActivities";
export { useOrdersPage } from "./hooks/client/useOrdersPage";
export { useOrdersFilter } from "./hooks/client/useOrdersFilter";
export { useOrdersFilterV2 } from "./hooks/client/useOrdersFilterV2";
export { useTransactions } from "./hooks/client/useTransactions";
export { useSettingsInfoForm } from "./hooks/client/useSettingsInfoForm";
export { useSettingsAddressForm } from "./hooks/client/useSettingsAddressForm";
export { useSettingsPasswordForm } from "./hooks/client/useSettingsPasswordForm";
export { useSettingsPhoto } from "./hooks/client/useSettingsPhoto";
export { useSettingsPhotoCrop } from "./hooks/client/useSettingsPhotoCrop";
export { useBaseUploadForm } from "./hooks/client/useBaseUploadForm";
export { useCardList } from "./hooks/client/useCardList";
export { useCardCreate } from "./hooks/client/useCardCreate";
export { useCardView } from "./hooks/client/useCardView";
export { useCardActions } from "./hooks/client/useCardActions";
export { useCardPassword } from "./hooks/client/useCardPassword";
export { useCardNumberAndCvv } from "./hooks/client/useCardNumberAndCvv";
export { useCardName } from "./hooks/client/useCardName";
export { useCardActivate } from "./hooks/client/useCardActivate";
export { useCardFreeze } from "./hooks/client/useCardFreeze";
export { useActiveCards } from "./hooks/client/useActiveCards";
export { useDownloadStatement } from "./hooks/client/useDownloadStatement";
export { collectDeviceData } from "./utils";

export { useQRCode } from "./hooks/useQRCode";
export { useByCrypto } from "./hooks/TopUpCard/useByCrypto";
export { useTUCardStore } from "./store/useTopUpCard";
export { useInstruction } from "./hooks/TopUpCard/useInstruction";
export { useTransactionHash } from "./hooks/TopUpCard/useTransactionHash";

export { useInformation } from "./hooks/TopUpCard/useInformation";
export { useStatus } from "./hooks/TopUpCard/useStatus";
export { useTopUpCard } from "./hooks/TopUpCard/useTopUpCard";
export { useCurrencyTable } from "./hooks/TopUpCard/useCurrencyTable";
export { useApp } from "./hooks/useApp";
export { useLang } from "./hooks/useLang";
export { useInfoPage } from "./hooks/useInfoPage";
export { useHeaderAuth } from "./hooks/useHeaderAuth";
export { useLoginForm } from "./hooks/useLoginForm";
export { useRegisterForm } from "./hooks/useRegisterForm";
export { useRatesFiat } from "./hooks/useRatesFiat";
export { useSignUpForm } from "./hooks/useSignUpForm";
export { useContactForm } from "./hooks/useContactForm";
export { useContactFormV2 } from "./hooks/useContactFormV2";
export { useRecoveryForm } from "./hooks/useRecoveryForm";
export { useRecoveryNewPassword } from "./hooks/useRecoveryNewPassword";
export { useAffiliateForm } from "./hooks/useAffiliateForm";
export { useAvailableAssets } from "./hooks/useAvailableAssets";
export { useAvailableCountry } from "./hooks/useAvailableCountry";
export { useAvailableCountries } from "./hooks/useAvailableCountries";
export { useExchangeCartTotals } from "./hooks/useExchangeCartTotals";
export { useExchangeFormMini } from "./hooks/useExchangeFormMini";
export { useExchangePage } from "./hooks/useExchangePage";
export { useExchangeStep0 } from "./hooks/useExchangeStep0";
export { useExchangeStep1 } from "./hooks/useExchangeStep1";
export { useExchangeStep1Verify } from "./hooks/useExchangeStep1Verify";
export { useExchangeStep2 } from "./hooks/useExchangeStep2";
export { useExchangeStep3 } from "./hooks/useExchangeStep3";
export { useExchangeStep3Upload } from "./hooks/useExchangeStep3Upload";
export { useExchangeStep4 } from "./hooks/useExchangeStep4";
export { useExchangeStep5 } from "./hooks/useExchangeStep5";
export { useExchangeStep5Cart } from "./hooks/useExchangeStep5Cart";
export { UsherProvider } from "./components/UsherProvider";
export { useStoreMain } from "./store/useStoreMain";
export { getIsAuthorized } from "./store/selectors/MainSelectors";
export { useStoreExchange } from "./store/useStoreExchange";
export { selectFetchPrices } from "./store/selectors/ExchangeSelectors";
export { useStoreExchangeRates } from "./store/useStoreExchangeRates";
export { useStoreCardView } from "./store/useStoreCardView";
export type {
  Rate,
  PaymentType,
  ExchangeSelector,
  ExchangeStep1Values,
  ExchangeStep2Values,
  ExchangeStep3Values,
  ExchangeStep4Values,
  ExchangeStep5Values,
} from "./types/ExchangeTypes";

export { getValue4Select } from "./helpers/CtrlHelpers";

export {
  VALID_NAME,
  VALID_EMAIL,
  VALID_PHONE,
  VALID_PASSWORD,
} from "./constants/ValidationConstants";
export {
  EXCHANGE_TYPE_FIAT,
  EXCHANGE_TYPE_CRYPTO,
} from "./constants/ExchangeConstants";
export { maskCardNumber } from "./utils";

export { AutoLogout } from "./components/AutoLogout";

// TODO: move to separate lib
export const utils = {
  ArrayUtils,
  DynamicUiUtils,
  Emitter,
  CLIENT_EVENTS,
  NetUtils,
  StringUtils,
  UrlUtils,
  ViewUtils,
};
export const types = {
  ExDocumentType,
  ExTransactionStatus,
  uploadModalInfo,
  ExceptionCodes,
};

export {
  CARD_DELIVERY_TYPES,
  CARD_TYPES,
  CARD_TYPE_PHYSICAL,
  CARD_TYPE_VIRTUAL,
} from "./constants/CardConstants";
export type { CardListFilterData } from "./types/CardTypes";
export const components = {
  Timer,
  CurrencyInput,
  CurrencySelect,
  CurrencySelectNative,
  OptionsApiData,
  CountrySelectNative,
  DefaultSelectNative,
};
export { ECardType } from "./constants/CardConstants";
export { EDepositStatus } from "./ApiData";
export type { Limits } from "./store/useStoreCardView";
export { ClientType } from "./types/CommonTypes";
// https://www.typescriptlang.org/docs/handbook/modules.html
