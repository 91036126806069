import { useCallback, useEffect, useMemo, useState } from "react";
import shallow from "zustand/shallow";
import { maskCardNumber } from "../../utils";
import { useTUCardStore } from "../../store/useTopUpCard";
import { useHistory, useLocation } from "react-router-dom";
import { ExClient } from "../../ExClient";
import { toast } from "react-toastify";

export const useByCrypto = () => {
  const history = useHistory();
  const [loading, setLoading] = useState("");
  const [errors, setErrors] = useState<{
    deposit: boolean;
    card: boolean;
  } | null>(null);

  const { currencies, deposit, cards, card, fiat } = useTUCardStore(
    (state) => ({
      fiat: state.fiat,
      currencies: state.currencies,
      deposit: state.deposit,
      cards: state.cards,
      card: state.card,
    }),
    shallow
  );
  const setCrypto = useTUCardStore((state) => state.setCrypto);
  const fetchCurrencies = useTUCardStore((state) => state.fetchCurrencies);
  useEffect(() => {
    fetchCurrencies();
  }, [fetchCurrencies]);

  useEffect(() => {
    setErrors(null);
  }, [deposit, card]);

  const CARDS_OPTIONS = useMemo(
    () =>
      (Array.isArray(cards) ? cards : [])?.map((card) => ({
        value: card.id,
        label: maskCardNumber(card.card_pan),
      })) ?? [],
    [cards]
  );

  const goToNextStep = useCallback(
    async (path: string, crpyto: string, spend: string) => {
      if (
        deposit === "" ||
        parseFloat(deposit) == 0 ||
        deposit == null ||
        card == null
      ) {
        setErrors({
          card: card == null,
          deposit: deposit === "" || deposit == null,
        });
      } else {
        setLoading(crpyto);
        const { data } = await ExClient.check_limit({
          crypto_currency: crpyto,
          crypto_amount: spend,
          fiat_currency: fiat,
        });
        if (typeof data === "object") {
          toast.error(
            `Crypto amount should be greater or equal ${data.min} ${crpyto} and less than ${data.max} ${crpyto}`
          );
          setLoading("");
          return;
        }
        setCrypto(crpyto);
        history.push(path);
      }
    },
    [deposit, fiat, card]
  );

  return {
    FIAT_OPTIONS: currencies.fiat,
    CARDS_OPTIONS,
    goToNextStep,
    errors,
    loading,
  };
};
