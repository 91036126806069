import { toast } from "react-toastify";
import { ExClient } from "../../ExClient";
import { useStoreClient } from "../../store/useStoreClient";
import { useStoreCardView } from "../../store/useStoreCardView";

export const useCardActivate = ({
  card_id,
  onSuccess,
}: {
  card_id: number;
  onSuccess: () => void;
}) => {
  const clearCache = useStoreClient((state) => state.clearCache);
  const fetchDetails = useStoreCardView((state) => state.fetchCardDetails);
  const activateCard = async () => {
    const result = await ExClient.activateCard({ card_id });
    if (typeof result === "string") {
      toast.error(result);
      return;
    }
    if (result?.success) {
      clearCache();
      await fetchDetails(card_id, true);
      onSuccess();
      toast.success("Card activated");
    } else {
      onSuccess();
      toast.error(result.message);
    }
  };

  return { activateCard };
};
