import { useCallback, useEffect } from "react";
import { useStoreClient } from "../../store/useStoreClient";
import shallow from "zustand/shallow";

export const useActiveCards = () => {
  const {
    fetchActiveCards,
    activeCards,
    loading,
    isCardActive,
    isAllExist,
    cards,
  } = useStoreClient(
    (state) => ({
      fetchActiveCards: state.fetchActiveCards,
      activeCards: state.activeCards,
      loading: state.activeCardsLoading,
      isCardActive: state.user.card_active,
      isAllExist: state.isAllExist,
      cards: state.clientCards,
    }),
    shallow
  );

  useEffect(() => {
    void fetchActiveCards(true);
  }, [fetchActiveCards]);

  return {
    activeCards,
    loading,
    isCardActive,
    isAllExist,
    cards,
  };
};
