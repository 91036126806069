//@ts-nocheck
export abstract class UrlUtils {

    public static getUrlParamsAsArray()
    {
        var vars = {}, hash;
        var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
        for(var i = 0; i < hashes.length; i++)
        {
            hash = hashes[i].split('=');
            vars[hash[0]] = hash[1];
        }
        return vars;
    }

    public static getUrlParam(key: string)
    {
        const query = new URLSearchParams(window.location.search);
        return query.get(key);
    }

    public static redirectTo(url: string)
    {
        window.location.href = url;
    }

    public static getPage(): string
    {
        const subdirs = window.location.pathname.split("/");

        return subdirs[subdirs.length - 1];
    }

    public static arrayToGetParams(arr: any)
    {
        return Object.keys(arr).map(function(key) {
            return key + '=' + arr[key]
        }).join('&');
    }
}
