import { ECardType } from "../constants/CardConstants";
import {
  ICreatePaymentResponse,
  IDepositAddress,
  IDepositStatus,
} from "../ApiData";
import {
  ExchangeCurrencies,
  ExchangePrices,
  fetchExchangeCurrencies,
  fetchExchangePrices,
  fetchOrderCardAmounts,
  fetchUserCards,
} from "./apiRequests/exchangeRequests";
import create, { State } from "zustand";
import { getDefaultFiatAmount } from "./useStoreExchange";
import { persist } from "zustand/middleware";

export interface ICard {
  id: string;
  card_pan: string;
}

export interface IPaymentData extends IDepositAddress {
  token: string;
  transactionId: number;
  exTransactionId: number;
  timestamp: number;
}
interface IValues extends State {
  amounts?: { order_fee: number; monthly_fee: number };
  prices: ExchangePrices;
  deposit: string;
  fiat: string;
  cryptoAmount: string;
  cryptoFee: string;
  crypto: string;
  cards: ICard[];
  card?: ICard;
  cardType: ECardType;
  wallet?: string;
  paymentData?: IPaymentData | null;
  currencies: ExchangeCurrencies;
  cardTypeId?: string;
  depositData?: IDepositStatus;
}
export interface ITUCard extends IValues {
  setCardType: (cardType: string) => void;
  fetchUserCards: (type: ECardType) => void;
  setCard: (id: string) => void;
  fetchPrices: () => void;
  setDeposit: (value: string) => void;
  setFiat: (value: string) => void;
  setCrypto: (value: string, reset?: boolean) => void;
  fetchCurrencies: () => void;
  setWallet: (wallet: string) => void;
  setCreateCard: (card: ICard, type: string) => void;
  setDepositStatus: (data: IDepositStatus) => void;
  fetchOrderCardAmounts: () => void;
  reset: () => void;
}
const INITIAL: IValues = {
  currencies: { crypto: [], fiat: [] },
  prices: { crypto: {}, fees: [], fiat: {} },
  cards: [],
  cardType: ECardType.Physical,
  crypto: process.env.REACT_APP_DEFAULT_CRYPTO_CURRENCY || "BTC",
  cryptoAmount: "",
  cryptoFee: "",
  deposit: getDefaultFiatAmount(),
  fiat: process.env.REACT_APP_DEFAULT_FIAT_CURRENCY || "EUR",
};
export const useTUCardStore = create<ITUCard>(
  persist(
    (set, get) => ({
      ...INITIAL,
      setDepositStatus: (data) =>
        set((state) => ({ ...state, depositData: data })),
      setCreateCard: (card, type) => {
        set((state) => ({ ...state, card, cardTypeId: type }));
      },
      fetchUserCards: async (type) => {
        const { data } = await fetchUserCards(type);
        set((state) => ({
          ...state,
          cards: data ?? [],
          card: data?.[0],
        }));
      },

      fetchOrderCardAmounts: async () => {
        const result = await fetchOrderCardAmounts();
        set((state) => ({
          ...state,
          amounts: {
            monthly_fee: Number(result.monthly_fee),
            order_fee: Number(result.order_fee),
          },
        }));
      },
      setWallet: (wallet) => set({ wallet }),
      fetchPrices: async () => {
        const prices = await fetchExchangePrices("get_rates_card");
        set({ prices });
      },

      fetchCurrencies: async () => {
        const currencies = await fetchExchangeCurrencies();
        set({ currencies });
      },
      setCardType: (type) => {
        set({
          cardType:
            type === "Physical" ? ECardType.Physical : ECardType.Virtual,
        });
      },
      setCard: (id) => {
        set({
          card: get().cards.find((card) => card.id === id),
        });
      },
      setDeposit: (value) => {
        const amount = parseFloat(value);
        if (!/^\d+\.?\d{0,2}$/.test(value) && value !== "") return;
        if (amount < 0 || amount > 1000000) return;
        set({
          deposit: value,
        });
        calculateCrypto();
      },
      setFiat: (value) => {
        set({ fiat: value });
        calculateCrypto();
      },
      setCrypto: (value) => {
        set({ crypto: value });
        calculateCrypto();
      },
      reset: () => {
        set(INITIAL);
      },
    }),
    { name: "TOP_UP_CARD_STORE", getStorage: () => sessionStorage }
  )
);

const calculateCrypto = () => {
  const { prices, fiat, crypto, deposit } = useTUCardStore.getState();
  const cryptoAmount =
    parseFloat(deposit) * prices.fiat[fiat] * prices.crypto[crypto];
  const fee = prices.fees.find((fee) => fee.symbol === crypto);
  useTUCardStore.setState({
    cryptoAmount: cryptoAmount.toFixed(5),
    cryptoFee: ((cryptoAmount * (fee?.commission ?? 0)) / 100).toFixed(5),
  });
};
