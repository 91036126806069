import { useEffect, useState } from "react";
import { History } from "history";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import { ExClient } from "../ExClient";
import { ExceptionCodes } from "../ApiData";
import { useStoreMain } from "../store/useStoreMain";
import { selectSetIsAuthorized } from "../store/selectors/MainSelectors";
import { cfgExchange } from "../DefaultValues";
import { UrlUtils } from "../utils/UrlUtils";
import { collectDeviceData } from "../utils";

type LoginFormProps = {
  history: History;
  msgConfirmIP?: string;
  msgConfirmEmail?: string;
  msgVerifySent?: string;
  msgUnusualActivity?: string;
};

type LoginFormReturn = {
  formMethods: UseFormReturn<{ email: string }>;
  onSubmit: (data: any) => void;
  loading: boolean;
};
export interface IOAuthParams {
  client_id: string;
  redirect_uri: string;
  state: string;
  response_type: string;
  scope: string;
}
export const useLoginForm = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  history,
  msgConfirmIP = "Your IP has been verified!",
  msgConfirmEmail = "Your Email has been verified!",
  msgVerifySent = "Verification email was sent to your email!",
  msgUnusualActivity = "Unusual activity detected. Verify your IP. Instructions were sent to your email.",
}: LoginFormProps): LoginFormReturn => {
  const [loading, setLoading] = useState(false);
  const setIsAuthorized = useStoreMain(selectSetIsAuthorized);
  const authParams = new URLSearchParams(window.location.search);
  const urlParams: IOAuthParams = {
    client_id: authParams.get("client_id"),
    redirect_uri: authParams.get("redirect_uri"),
    response_type: authParams.get("response_type"),
    scope: authParams.get("scope"),
    state: authParams.get("state"),
  };
  const email = UrlUtils.getUrlParam("email");
  const formMethods = useForm<{ email: string }>({
    defaultValues: { email },
  });
  useEffect(() => {
    switch (location.hash) {
      case "#success_confirm_ip":
        toast.info(msgConfirmIP);
        break;

      case "#success_confirm_email":
        toast.info(msgConfirmEmail);
        break;
    }
  }, []);

  const onSubmit = async function(data: any) {
    try {
      const device_info = JSON.stringify(collectDeviceData());
      const isParamsExist = urlParams.client_id != null;
      setLoading(true);
      const success = await ExClient.login(
        data.email,
        data.password,
        device_info,
        isParamsExist ? urlParams : undefined
      );
      if (typeof success === "string") {
        window.location.replace(success);
        return;
      }
      if (!success) return;
      if (email && email !== "") {
        setIsAuthorized(true);
        history.push(`/exchange/${cfgExchange.STEPS.STEP1.route}`);
      } else ExClient.redirectLogin();
    } catch (e) {
      const err = e as { code: ExceptionCodes; message: string };
      if (err.code === ExceptionCodes.VERIFICATION_EMAIL_CODE) {
        toast.info(msgVerifySent);
      } else if (
        err.code === ExceptionCodes.NO_AUTHORIZATION_NOT_ALLOWED_IP_CODE
      ) {
        toast.error(msgUnusualActivity);
      } else toast.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  return {
    formMethods,
    onSubmit,
    loading,
  };
};
