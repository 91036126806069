import React, {Component} from "react";

interface ICommonMenuMenuProps {
    onSelect: (data: any, index: number) => void
    labels: any[]  // or other data
    innerTextKey?: string  // if other date, key to get text from clicked item
    selectedIndexDefault?: number
    updateOnLoad?:boolean
    rootClass?:string
    itemClass?:string
    titleClass?:string
}

export class CommonMenuModel extends Component<ICommonMenuMenuProps, {}> {
    state = {
        selectedIndex: this.props.selectedIndexDefault == null ? 0 : this.props.selectedIndexDefault
    };

    onSelect =  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        this.onSelectWithoutPrevent(e);
    }

    onSelectWithoutPrevent =  (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const val = e.currentTarget.innerText;

        const index = this.props.innerTextKey == null ? this.props.labels.indexOf(val) : this.props.labels.findIndex((e => e[this.props.innerTextKey] == val));

        if(this.state.selectedIndex !== index) {
            this.select(index, this.props.labels[index])
        }
    }

    select = (index: number, val: any) => {
        this.setState({selectedIndex: index});
        this.props.onSelect(val, index);
    }

    setSelectedIndex = (index: number) => {
        this.select(index, this.props.labels[index])
    }

    componentDidMount() {
        const i = this.state.selectedIndex;
        if(this.props.labels.length > i && this.props.updateOnLoad) {
            this.props.onSelect(this.props.labels[i], i);
        }
    }
}
