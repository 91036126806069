import { useEffect, useState } from "react";
import { History } from "history";
import { toast } from "react-toastify";
import { ApiData } from "../ApiData";
import { UrlUtils } from "../utils/UrlUtils";
import { ExTransactionStatus } from "../types/ExTransactionStatus";
import { cfgExchange } from "../DefaultValues";
import { useStoreExchangePage } from "../store/useStoreExchangePage";
import {
  selectSetStep1,
  selectStep1,
  selectStep4,
} from "../store/selectors/ExchangePageSelectors";

type ExchangeStep5Props = {
  history: History;
};

type ExchangeStep5Return = {
  result: {
    id: string;
    info: string;
    status: string;
    status_id: number;
  };
  fetchStatus: () => void;
  onBuyAgain: () => void;
  onTryAgain: () => void;
  loading: boolean;
};

export const useExchangeStep5 = ({
  history,
}: ExchangeStep5Props): ExchangeStep5Return => {
  const [loading, setLoading] = useState(false);
  const setStep1 = useStoreExchangePage(selectSetStep1);
  const { paymentType } = useStoreExchangePage(selectStep1);
  const { payment_system_id } = useStoreExchangePage(selectStep4);

  const [result, setResult] = useState({
    id: "",
    info: "",
    status: "Pending",
    status_id: ExTransactionStatus.STATUS_PENDING,
  });

  const fetchStatus = async () => {
    setLoading(true);
    const { success, message, data } = await ApiData.exchangeApiRequest(
      {
        payment_token: localStorage.getItem("payment_token"),
      },
      "get_status"
    );

    if (!success) {
      setLoading(false);
      toast.error(message);
      return;
    }
    setResult({
      ...data,
      status_id: parseInt(data.status_id),
    });
    setLoading(false);
  };

  const onTryAgain = () => {
    setStep1({
      tryAgain: true,
      paymentType: {
        ...paymentType,
        id: payment_system_id ? payment_system_id.toString() : "0",
      },
    });
    history.push(cfgExchange.STEPS.STEP4.route);
  };

  useEffect(() => {
    if (!localStorage.getItem("payment_token")) return UrlUtils.redirectTo("/");

    fetchStatus().then();
  }, []);

  return {
    result,
    fetchStatus,
    onBuyAgain: () => history.push(cfgExchange.STEPS.STEP1.route),
    onTryAgain,
    loading,
  };
};
