import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { ApiData, IApiResponse } from "../../ApiData";
import { StringUtils } from "../../utils/StringUtils";

type Option = {
  value: string;
  label: string;
};

type Options = {
  fiat: Option[];
  crypto: Option[];
  status: Option[];
  period?: Option[];
};

type FormValues = {
  fiat: Option;
  crypto: Option;
  status: Option;
  period: Option;
  date: Date | Date[];
};

type OrdersFilterProps = {
  onFilter: (filter?: any) => void;
  type?: string;
  compact?: boolean;
};

type OrdersFilterReturn = {
  options: Options;
  onReset: () => void;
  onSubmit: (data: any) => void;
  formMethods: UseFormReturn<FormValues>;
};

const defaultOption = { value: "0", label: "All" };
const defaultOptions: Options = {
  fiat: [defaultOption],
  crypto: [defaultOption],
  status: [defaultOption],
  period: [
    { value: "0", label: "All time" },
    { value: "1", label: "Custom" },
  ],
};

const getOptions = async (name: string) => {
  return (await ApiData.getSelectorList(name, {}, false)).map(
    ({ key, value }) => ({
      label: key,
      value,
    })
  );
};

type Response = IApiResponse<{ [key: number]: string }>;
const getSellOptions = async (type: string = "Card") => {
  const action = type === "Card" ? "get_card_status" : "get_sell_status";
  const cachedData = sessionStorage.getItem(action);
  if (cachedData != null) return JSON.parse(cachedData) as Response;
  const data = (await ApiData.clientApiRequest(
    {},
    action,
    type === "Card" ? "cards" : "wallet"
  )) as Response;
  sessionStorage.setItem(action, JSON.stringify(data));
  return data;
};

export const useOrdersFilter = <
  TFormValues extends Record<string, any> = Record<string, any>
>({
  onFilter,
  type = "Buy",
  compact,
}: OrdersFilterProps): OrdersFilterReturn => {
  const [options, setOptions] = useState(defaultOptions);
  const formMethods = useForm<FormValues>({
    defaultValues: {
      fiat: defaultOption,
      crypto: defaultOption,
      status: defaultOption,
      period: defaultOptions.period[0],
      date: [new Date(), new Date()],
    },
  });

  useEffect(() => {
    const load = async () => {
      const fiat = await getOptions("fiat");
      const crypto = await getOptions("crypto");
      const status = await getOptions("trans_status");
      const sell_options = (await getSellOptions(type)).data;
      const sellStatus = Object.keys(sell_options).map((option) => ({
        //@ts-ignore
        label: sell_options[option],
        value: option,
      }));
      const isBuy = type === "Buy";
      const isCard = type === "Card";
      setOptions((options) => ({
        ...options,
        fiat: isBuy
          ? [...options.fiat, ...fiat]
          : [...options.crypto, ...crypto],
        crypto: isBuy
          ? [...options.crypto, ...crypto]
          : [...options.fiat, ...fiat],
        status: [
          ...options.status,
          ...(isBuy
            ? status
            : sellStatus.filter(
                (status) => isCard || !/charged/i.test(status.label)
              )),
        ],
      }));
    };
    load().then();
  }, [type]);
  const onReset = () => formMethods.reset();

  const onSubmit = (data: FormValues) => {
    const isBuy = type === "Buy";
    const filter = {
      from: "",
      to: "",
      [compact ? "status" : "status_id"]: data.status.value,
    };
    if (isBuy)
      Object.assign(filter, {
        [compact ? "currency_id" : "fiat_currency_id"]: data?.fiat?.value,
        crypto_currency_id: data?.crypto?.value,
      });
    else
      Object.assign(filter, {
        [compact ? "currency_id" : "fiat_currency_id"]: data?.crypto?.value,
        crypto_currency_id: data?.fiat?.value,
      });
    if (data.period.value === "1") {
      //@ts-ignore
      filter.time_from = StringUtils.getDateFilterString(data.date[0]);
      //@ts-ignore
      filter.time_to = StringUtils.getDateFilterString(data.date[1]);
    }

    onFilter(filter);
  };

  return {
    options,
    onReset,
    onSubmit,
    formMethods,
  };
};
