import { useEffect } from "react";
import { useStoreCardView } from "../../store/useStoreCardView";
import { selectCardView } from "../../store/selectors/ClientSelectors";
import shallow from "zustand/shallow";

export const useCardView = (cardId: string) => {
  const { fetchCardDetails, loading, card } = useStoreCardView(
    selectCardView,
    shallow
  );
  useEffect(() => {
    fetchCardDetails(Number(cardId), true);
  }, [fetchCardDetails, cardId]);
  return {
    card,
    loading,
  };
};
