import { useCallback, useEffect, useState } from "react";
import { ExClient, ICard } from "../../ExClient";
import { useStoreClient } from "../../store/useStoreClient";
import { selectClientCards } from "../../store/selectors/ClientSelectors";
import shallow from "zustand/shallow";

export const useCardList = () => {
  const {
    totalRecords,
    cardTypes,
    cards,
    fetchClientCards,
    loading,
    setFilter,
    filter,
    isAllExist,
  } = useStoreClient(selectClientCards, shallow);

  useEffect(() => {
    fetchClientCards(true);
  }, [fetchClientCards]);

  const pageCount = Math.ceil(totalRecords / 6);

  return {
    cards,
    loading,
    cardTypes,
    totalRecords,
    pageCount,
    filter,
    setFilter,
    isAllExist,
  };
};
