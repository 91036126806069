export const VALID_NAME = {
  minLength: {
    value: 2,
    message: "The minimum field length 2 symbols."
  },
  pattern: {
    value: /^[a-zA-Z\s]*$/,
    message: "Only Latin letters are allowed."
  }
};

export const VALID_EMAIL = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Invalid email address."
  }
};

export const VALID_PHONE = {
  minLength: {
    value: 8,
    message: "The minimum field length 8 digits."
  },
  maxLength: {
    value: 14,
    message: "The maximum field length 14 digits."
  },
  pattern: {
    value: /^[\+]?[0-9]{3}[0-9]{3}[0-9]{4,8}$/,
    message: "Invalid phone number. For example +18123452679"
  }
};

export const VALID_PASSWORD = {
  minLength: {
    value: 6,
    message: "The minimum field length 6 symbols."
  }
};
