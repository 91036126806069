import React, {Component} from "react";
import {DefaultSelectNative, Opt} from "./DefaultSelectNative";

export interface CurrencySelectNativeProps {
    selected?: any;
    type: string,
    onChangeCurrency?:  (event: Opt) => void,
    with_default?:boolean,

    className?: string
    classNamePrefix?: string
    styles?:any
    isSearchable?:boolean
}

export class CurrencySelectNative extends Component<CurrencySelectNativeProps> {

    render = () => (
        <DefaultSelectNative
            className={this.props.className} classNamePrefix={this.props.classNamePrefix} styles={this.props.styles}
            selected={this.props.selected} type={this.props.type} onChange={this.props.onChangeCurrency} key_equals_value={false} with_default={this.props.with_default}
            isSearchable={this.props.isSearchable == null ? false : this.props.isSearchable}
        />
    );
}
