export let DefaultValues: {
  HOME?: string
  CLIENT?: string
  PAY: string
  SECURE: string
} = {
  HOME: '/',
  CLIENT: '/client',
  PAY: 'https://devpay.wisenex.com/',
  SECURE: 'https://devsecure.wisenex.com/',
}

export function setConfigUrls(urls: typeof DefaultValues) {
  DefaultValues = { ...DefaultValues, ...urls }
}

export let cfgExchange: {
  STEPS?: {
    STEP1: { route: string, title: string },
    STEP2: { route: string, title: string },
    STEP3: { route: string, title: string },
    STEP4: { route: string, title: string },
    STEP5: { route: string, title: string },
  }
} = {
  STEPS: {
    STEP1: { route: 'transaction-details', title: 'Transaction Details' },
    STEP2: { route: 'personal-info', title: 'Personal Info' },
    STEP3: { route: 'document-verification', title: 'Document Verification' },
    STEP4: { route: 'payment', title: 'Payment' },
    STEP5: { route: 'status-check', title: 'Status Check' },
  }
}

export const setConfigExchange = (settings: typeof cfgExchange) => {
  cfgExchange = { ...cfgExchange, ...settings }
}
