import React, { useRef } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import { ApiData } from "../ApiData";

type FormData = {
  name: string;
  email: string;
  phone: string;
  subject: string;
  message: string;
  captcha: string;
  sex: string;
};

type ContactFormV2Return = {
  onSubmit: (data: FormData) => void;
  formMethods: UseFormReturn<FormData>;
  captchaRef: React.MutableRefObject<any>;
  onCaptchaChange: (value: string) => void;
};

export const useContactFormV2 = <
  TFormValues extends Record<string, any> = Record<string, any>
>(
  onSuccess?: () => void
): ContactFormV2Return => {
  const formMethods = useForm<FormData>({
    defaultValues: {
      sex: "1",
    },
  });

  const onSubmit = async (data: FormData) => {
    const { success, message } = await ApiData.publicApiRequest(
      {
        ...data,
        "g-recaptcha-response": data.captcha,
      },
      "send_ticket"
    );

    if (!success) return toast.error(message);

    toast.success("Thank you for your request!");
    onSuccess?.();
    formMethods.reset();
    captchaRef.current.reset();
  };

  const captchaRef = useRef(null);
  const onCaptchaChange = (value: string) => {
    formMethods.setValue("captcha", value, { shouldValidate: true });
  };

  return {
    onSubmit,
    formMethods,
    captchaRef,
    onCaptchaChange,
  };
};
