import React, {Component} from "react";
import OptionsApiData from "./OptionsApiData";


export interface CurrencySelectProps {
    currency: string;
    type: string,
    className: string,
    onChangeCurrency?:  (event: React.ChangeEvent<HTMLSelectElement>) => void,
    onChangeOption?:  (val: HTMLOptionElement) => void,
    with_default?: boolean
    key_equals_value?: boolean
}

export class CurrencySelect extends Component<CurrencySelectProps> {
    selector: React.RefObject<HTMLSelectElement > = React.createRef();

    onChange =  (event: React.ChangeEvent<HTMLSelectElement>) => {
        if(this.props.onChangeCurrency)
            this.props.onChangeCurrency(event);

        if(this.props.onChangeOption)
            this.props.onChangeOption(event.target.selectedOptions[0]);

    }

    render = () => (
        <select ref={this.selector} name={this.props.type + '_select'}  className={this.props.className} onChange={this.onChange} value={this.props.currency}>
            <OptionsApiData type={this.props.type}  key_equals_value={this.props.key_equals_value == null ? true : this.props.key_equals_value} with_default={this.props.with_default} />
        </select>
    );
}
