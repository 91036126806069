import { useEffect, useState } from "react";
import { useStoreMain } from "../../store/useStoreMain";
import { useStoreClient } from "../../store/useStoreClient";
import {
  selectSetUser,
  selectUser,
} from "../../store/selectors/ClientSelectors";
import {
  getIsAuthorized,
  selectInit,
} from "../../store/selectors/MainSelectors";
import { ExClient } from "../../ExClient";
import { ExClientInfo } from "../../ExClientInfo";
import { UrlUtils } from "../../utils/UrlUtils";
import { CLIENT_EVENTS, Emitter, EmitterEvents } from "../../utils/EventUtils";
import { DefaultValues } from "../../DefaultValues";
import { clearStorage } from "../../utils";
import { ClientType } from "../../types/CommonTypes";

type ClientAppReturn = {
  isInitialized: boolean;
  isCardActive?: boolean;
};

export const useClientApp = (isWizar?: boolean): ClientAppReturn => {
  const init = useStoreMain(selectInit);
  const setUser = useStoreClient(selectSetUser);
  const fetchActiveCards = useStoreClient((state) => state.fetchActiveCards);
  const user = useStoreClient(selectUser);
  const isAuthorized = useStoreMain(getIsAuthorized);
  const [isInitialized, setIsInitialized] = useState(false);
  useEffect(() => {
    clearStorage();
    fetchActiveCards();
  }, []);
  useEffect(() => {
    init().then((loggedIn) => {
      if (window.location.pathname.includes("/client/widgets")) return;
      if (!loggedIn) UrlUtils.redirectTo(`${DefaultValues.HOME}login`);
    });
  }, [init]);

  useEffect(() => {
    if (!isAuthorized) return;

    const unsubscribeClientInfoEvent = CLIENT_EVENTS.onClientInfoUpdate(
      (payload: any) => {
        const {
          id,
          email,
          first_name,
          last_name,
          address,
          city,
          state,
          zip,
          country_code,
          country_name,
          phone,
          sex,
          verify_flags,
          date_created,
          date_last_login,
          avatar,
          app,
        } = payload;
        setUser({
          id: parseInt(id),
          email,
          first_name,
          last_name,
          address,
          city,
          state,
          zip,
          country_code,
          country_name,
          phone,
          sex: parseInt(sex),
          is_verified: ExClientInfo.checkVerified(payload),
          verify_flags,
          date_created,
          date_last_login,
          avatar,
          app,
          card_active: payload.card_active,
          client_type:
            payload?.client === "onramp"
              ? ClientType.OnRamp
              : ClientType.Default,
        });
      }
    );

    const unsubscribeClientLoggedOutEvent = CLIENT_EVENTS.onClientLoggedOut(
      () => {
        sessionStorage.setItem("loggedOut", "1");
        UrlUtils.redirectTo(`${DefaultValues.HOME}login`);
      }
    );

    ExClient.getClientInfo().then((data: ExClientInfo) => {
      if (data) {
        console.log("Data is:", data);
        Emitter.emit(EmitterEvents.ClientInfoUpdate, data);
        Emitter.emit(EmitterEvents.ClientLogged, { client_id: data.id });
        setIsInitialized(true);
      }
    });

    return () => {
      unsubscribeClientInfoEvent();
      unsubscribeClientLoggedOutEvent();
    };
  }, [isAuthorized]);

  return {
    isInitialized,
    isCardActive: user.card_active,
  };
};
