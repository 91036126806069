import create, { GetState, SetState, State } from "zustand";
import { devtools, persist, StoreApiWithPersist } from "zustand/middleware";
import {
  ExchangePrices,
  ExchangeCurrencies,
  fetchExchangePrices,
  fetchExchangeCurrencies,
  fetchUserCards,
} from "../store/apiRequests/exchangeRequests";
import {
  EXCHANGE_STORAGE_KEY,
  EXCHANGE_TYPE_CRYPTO,
  EXCHANGE_TYPE_FIAT,
} from "../constants/ExchangeConstants";
import { CARD_TYPES, ECardType } from "../constants/CardConstants";

export interface ICard {
  id: string;
  card_pan: string;
}
CARD_TYPES;
export interface ExchangeStore extends State {
  isFetchingPrices: boolean;
  isFetchingCurrencies: boolean;
  calcFor: "fiat" | "crypto";
  prices: ExchangePrices;
  currencies: ExchangeCurrencies;
  fiatAmount: string;
  fiatCurrency: string;
  cryptoAmount: string;
  cryptoCurrency: string;
  cards: ICard[];
  currentCard?: ICard;
  cardType: ECardType;
  setCardType: (cardType: string) => void;
  fetchUserCards: (type: ECardType) => void;
  setCurrentCard: (id: string) => void;
  fetchPrices: () => void;
  fetchCurrencies: (paymentTypeId?: string) => void;
  setFiatAmount: (value: string) => void;
  setFiatCurrency: (value: string) => void;
  setCryptoAmount: (value: string) => void;
  setCryptoCurrency: (value: string, reset?: boolean) => void;
}

export function getDefaultFiatAmount() {
  return (process.env.REACT_APP_DEFAULT_FIAT_AMOUNT || 200).toString();
}

export const useStoreExchange = create<
  ExchangeStore,
  SetState<ExchangeStore>,
  GetState<ExchangeStore>,
  StoreApiWithPersist<ExchangeStore>
>(
  persist(
    devtools(
      (set, get) => ({
        cardType: ECardType.Physical,
        cards: [],
        isFetchingPrices: false,
        isFetchingCurrencies: false,
        calcFor: EXCHANGE_TYPE_FIAT,
        prices: { fiat: {}, crypto: {}, fees: [] },
        currencies: { fiat: [], crypto: [] },
        fiatAmount: getDefaultFiatAmount(),
        fiatCurrency: process.env.REACT_APP_DEFAULT_FIAT_CURRENCY || "EUR",
        cryptoAmount: "",
        cryptoCurrency: process.env.REACT_APP_DEFAULT_CRYPTO_CURRENCY || "BTC",
        setCardType: (type) => {
          set((state) => ({
            ...state,
            cardType:
              type === "Physical" ? ECardType.Physical : ECardType.Virtual,
          }));
        },
        fetchUserCards: async (type) => {
          const { data } = await fetchUserCards(type);
          set((state) => ({
            ...state,
            cards: data ?? [],
            currentCard: data?.[0],
          }));
        },
        setCurrentCard: (id) => {
          set((state) => ({
            ...state,
            currentCard: state.cards.find((card) => card.id === id),
          }));
        },
        fetchPrices: async () => {
          if (get().isFetchingPrices) return;
          set({ isFetchingPrices: true });

          const prices = await fetchExchangePrices();
          set({ isFetchingPrices: false, prices });
          recalculate();
        },
        fetchCurrencies: async (paymentTypeId = "") => {
          if (get().isFetchingCurrencies) return;
          set({ isFetchingCurrencies: true });

          const currencies = await fetchExchangeCurrencies(paymentTypeId);
          set({ isFetchingCurrencies: false, currencies });
          checkSelectedCurrency();
        },
        setFiatAmount: (value) => {
          const amount = parseFloat(value);
          if (!/^\d+\.?\d{0,2}$/.test(value) && value !== "") return;
          if (amount < 0 || amount > 1000000) return;

          set({ fiatAmount: value, calcFor: EXCHANGE_TYPE_FIAT });
          recalculate();
        },

        setFiatCurrency: (value) => {
          set({ fiatCurrency: value });
          recalculate();
        },
        setCryptoAmount: (value) => {
          const amount = parseFloat(value);
          if (!/^\d+\.?\d{0,6}$/.test(value) && value !== "") return;
          if (amount < 0 || amount > 1000000) return;

          set({ cryptoAmount: value, calcFor: EXCHANGE_TYPE_CRYPTO });
          recalculate();
        },
        setCryptoCurrency: (value, reset = true) => {
          if (value === "USDT-TRC20" && reset) {
            set({ cryptoCurrency: value, fiatAmount: "" });
          } else if (get().fiatAmount === "") {
            set({ cryptoCurrency: value, fiatAmount: getDefaultFiatAmount() });
          } else {
            set({ cryptoCurrency: value });
          }
          recalculate();
        },
      }),
      { name: "useStoreExchange" }
    ),
    {
      name: EXCHANGE_STORAGE_KEY,
      getStorage: () => sessionStorage,
    }
  )
);

const checkSelectedCurrency = () => {
  const {
    currencies,
    fiatCurrency,
    cryptoCurrency,
  } = useStoreExchange.getState();

  if (!currencies.fiat.includes(fiatCurrency) && currencies.fiat.length > 0) {
    useStoreExchange.setState({ fiatCurrency: currencies.fiat[0] });
  }

  if (
    !currencies.crypto.includes(cryptoCurrency) &&
    currencies.crypto.length > 0
  ) {
    useStoreExchange.setState({ cryptoCurrency: currencies.crypto[0] });
  }
  recalculate();
};

const recalculate = () => {
  const {
    prices,
    calcFor,
    fiatAmount,
    fiatCurrency,
    cryptoAmount,
    cryptoCurrency,
  } = useStoreExchange.getState();

  let result;
  if (calcFor === EXCHANGE_TYPE_FIAT) {
    result =
      parseFloat(fiatAmount) /
      prices.fiat[fiatCurrency] /
      prices.crypto[cryptoCurrency];
    useStoreExchange.setState({
      cryptoAmount: isNaN(result) ? "" : result.toFixed(6),
    });
  } else {
    result =
      parseFloat(cryptoAmount) *
      prices.fiat[fiatCurrency] *
      prices.crypto[cryptoCurrency];
    useStoreExchange.setState({
      fiatAmount: isNaN(result) ? "" : result.toFixed(2),
    });
  }
};
