import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useForm, UseFormReturn } from "react-hook-form";
import { CardStatus, MutationType } from "../../types/CardTypes";
import { useStoreCardView } from "../../store/useStoreCardView";
import { ExClient } from "../../ExClient";
import shallow from "zustand/shallow";

type FormData = {
  name: string;
};

type Props<T extends object> = {
  mutation: () => MutationType<T, { id: string; name: string }>;
  onSuccess: () => void;
  msgSuccess?: string;
};

type Return = {
  name: string;
  loading: boolean;
  isClosed: boolean;
  onSubmit: () => void;
  formMethods: UseFormReturn<FormData>;
};

export const useCardName = <Mutation extends object>({
  onSuccess,
  msgSuccess = "Card name has been changed.",
}: Props<Mutation>): Return => {
  const [loading, setLoading] = useState(false);
  const {
    card: { id, name, status },
    setCardName: updateCardName,
  } = useStoreCardView(
    (state) => ({
      card: state.card,
      setCardName: state.setCardName,
    }),
    shallow
  );

  // const [{ fetching }, setCardNameMutation] = mutation();
  const formMethods = useForm<FormData>({
    defaultValues: {
      name,
    },
  });

  const setCardName = useCallback(async () => {
    const { name } = formMethods.getValues();
    return await ExClient.editCardName(id, name);
  }, [id, formMethods]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const result = await setCardName();
    if (!result?.success) return toast.error(result.message);

    onSuccess();
    updateCardName(result.data);
    toast.success(msgSuccess);
    setLoading(false);
  }, [msgSuccess, onSuccess, setCardName]);

  return {
    name,
    loading,
    isClosed: status === CardStatus.Closed,
    onSubmit,
    formMethods,
  };
};
