import { DeliveryType } from "../types/CardTypes";

export const CARD_TYPE_PHYSICAL = "Physical";
export const CARD_TYPE_VIRTUAL = "Virtual";

export const CARD_TYPES = [CARD_TYPE_PHYSICAL, CARD_TYPE_VIRTUAL];
export enum ECardType {
  Virtual = 1,
  Physical = 2,
}
export const CARD_DELIVERY_TYPES = [
  {
    label: "DHL Express",
    value: DeliveryType.DhlExpress,
    time: "1-2 working days",
    price: "€20",
  },
  {
    label: "DHL Global Mail",
    value: DeliveryType.DhlGlobalMail,
    time: "3-6 working days",
    price: "€5",
  },
];
