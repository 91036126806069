import { useCallback, useState } from "react";
import { ExClient } from "../../ExClient";
import { selectUser } from "../../store/selectors/ClientSelectors";
import { useStoreClient } from "../../store/useStoreClient";

export const useDownloadStatement = () => {
  const { first_name, last_name } = useStoreClient(selectUser);
  const [loading, setLoading] = useState(false);

  const downloadStatement = useCallback(
    async (card_id?: number, filter?: Record<string, any>) => {
      setLoading(true);
      try {
        const blob = await ExClient.downloadClientStatement({
          card_id,
          filter,
        });
        const downloadLink = document.createElement("a");
        const fileName = `${first_name} ${last_name} card statement.pdf`;
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = fileName;
        downloadLink.target = "_blank";
        downloadLink.click();
      } finally {
        setLoading(false);
      }
    },
    [first_name, last_name]
  );

  return {
    downloadStatement,
    loading,
  };
};
